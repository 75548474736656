import { Badge, Timeline } from "flowbite-react";

const TutorialsPage = () => {
  return (
    <Timeline>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 1
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Seja bem vindo a Relinq!
          </Timeline.Title>
          <Timeline.Body>
            <iframe
              className="video-tutorials"
              width="560"
              height="290"
              src="https://www.youtube.com/embed/iShrvl6CF4Q?si=MXrnkkermVxz2j4n"
              title="Seja bem vindo a Relinq!"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 2
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Configurações inicias do sistema
          </Timeline.Title>
          <Timeline.Body>
            <iframe
              className="video-tutorials"
              width="560"
              height="290"
              src="https://www.youtube.com/embed/Ldzy8GyhsOE?si=wN1x4_qt6DrAsGg5&amp"
              title="Configurações inicias do sistema"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 3
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Criando categorias, serviços e produtos
          </Timeline.Title>
          <Timeline.Body>
            <iframe
              className="video-tutorials"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xovYGzoriKM?si=9SF9MkWKEL2bvMly"
              title="Criando categorias, serviços e produtos"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 4
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Criando e configurando os profissionais
          </Timeline.Title>
          <Timeline.Body>
            <iframe
              className="video-tutorials"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/QmPjKTlR2zY?si=KerJ_-79fkPjVOQR"
              title="Criando e configurando os profissionais"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 5
          </Timeline.Time>
          <Timeline.Title className="mb-2">Criando os clientes</Timeline.Title>
          <Timeline.Body>
            <Badge className="w-fit" color="pink">
              Em breve
            </Badge>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 6
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Criando os agendamentos
          </Timeline.Title>
          <Timeline.Body>
            <Badge className="w-fit" color="pink">
              Em breve
            </Badge>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 7
          </Timeline.Time>
          <Timeline.Title className="mb-2">Criando as comandas</Timeline.Title>
          <Timeline.Body>
            <Badge className="w-fit" color="pink">
              Em breve
            </Badge>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 8
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Visualizando o faturamento
          </Timeline.Title>
          <Timeline.Body>
            <Badge className="w-fit" color="pink">
              Em breve
            </Badge>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time className="text-gray-500 text-xs font-semibold">
            Vídeo 9
          </Timeline.Time>
          <Timeline.Title className="mb-2">
            Visualizando o dashboard
          </Timeline.Title>
          <Timeline.Body>
            <Badge className="w-fit" color="pink">
              Em breve
            </Badge>
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
    </Timeline>
  );
};

export default TutorialsPage;
