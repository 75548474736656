import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { CATEGORIES, PRODUCTS } from "../../config/apiConfig";
import { Badge, Button, Card, Pagination } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import EmptyTable from "./EmptyTable";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import { MdCategory, MdOutlineHistory } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import FilterToolbar from "../inputs/FilterToolbar";
import ProductForm from "../forms/ProductForm";
import { formatCurrencyBRL } from "../../utils/utils";
import { FaCircleExclamation } from "react-icons/fa6";
import ProductHistoryForm from "../forms/ProductHistoryForm";

const ProductsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const queryClient = useQueryClient();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [priceProductFilter, setPriceProductFilter] = useState("");
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState("");
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerHistoryOpen, setIsDrawerHistoryOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: products,
  } = useQuery(
    [
      "products",
      currentPage,
      priceProductFilter,
      selectedCategoryFilter,
      nameParam,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${PRODUCTS.GET_PRODUCTS}?page=${currentPage}&categoryId=${selectedCategoryFilter}&priceSell=${priceProductFilter}&name=${nameParam}`
      );
      setTotalPages(items.data.totalPages || 1);
      setTotalCount(items.data.totalCount || 0);

      return items.data.data;
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [nameParam]);

  const {
    isLoadingCategoriesFilter,
    errorCategoriesFilter,
    data: categoriesFilter,
  } = useQuery(["categoriesFilter"], async () => {
    const { data: items } = await axios.get(
      `${CATEGORIES.GET_CATEGORIES}?itemsPerPage=${-1}`
    );

    return items.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleAddProduct = () => {
    setSelectedProduct(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleViewHistoryProduct = (customer) => {
    setSelectedProduct(customer);
    setIsDrawerHistoryOpen((prevState) => !prevState);
  };

  const handleEditProduct = (customer) => {
    setTypeDrawer("EDIT");
    setSelectedProduct(customer);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteProduct = (customer) => {
    setSelectedProduct(customer);
    setOpenModalDelete(true);
  };

  const deleteProduct = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${PRODUCTS.GET_PRODUCT}/${selectedProduct.id}`);
      queryClient.invalidateQueries(["products"], currentPage);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  return (
    <>
      <div className="flex justify-between mb-1 mt-2 gap-2">
        <FilterToolbar
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        >
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <FaDollarSign className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0  appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={priceProductFilter}
              onChange={(e) => setPriceProductFilter(e.target.value)}
            >
              <option selected value={""}>
                Por preço
              </option>
              <option value="DESC">Mais caros</option>
              <option value="ASC">Mais baratos</option>
            </select>
          </div>
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <MdCategory className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedCategoryFilter}
              onChange={({ target }) => setSelectedCategoryFilter(target.value)}
            >
              <option selected value={""}>
                Por categoria
              </option>
              {categoriesFilter?.map((category) => (
                <option key={category.id} selected value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </FilterToolbar>
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddProduct}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Adicionar produto</span>
        </Button>
      </div>

      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome do produto..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>

      {!isLoading ? (
        <>
          {totalCount ? (
            <div className="text-sm mt-5">
              Total de <strong>{totalCount}</strong> produtos
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          role="status"
          className="max-w-sm animate-pulse mt-5"
          style={{ height: "20px" }}
        >
          <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
        </div>
      )}

      {!isLoading ? (
        <>
          {products?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4 mt-4">
              {products.map((product, index) => (
                <Card key={index} className="relative">
                  <div className="flex gap-3 items-center">
                    <h2>{product.name}</h2>
                  </div>
                  <div className="flex gap-1 text-gray-500">
                    <Icon fontSize="small">paid</Icon>
                    <span className="text-sm">
                      {formatCurrencyBRL.format(product.priceSell)}
                    </span>
                  </div>
                  <div className={`flex gap-1 text-gray-500 items-center`}>
                    <Icon fontSize="small">shopping_cart</Icon>
                    <Badge
                      color={
                        product.stock < product.minStock ? "pink" : "success"
                      }
                    >
                      <div className="flex items-center gap-1">
                        {product.stock < product.minStock && (
                          <FaCircleExclamation className="animate-pulse" />
                        )}
                        <div>Estoque: {product.stock || 0}</div>
                      </div>
                    </Badge>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8">
                    <MdOutlineHistory
                      fontSize={22}
                      className="cursor-pointer text-gray-500"
                      onClick={() => handleViewHistoryProduct(product)}
                    />
                    <Icon
                      fontSize="small"
                      className="cursor-pointer text-gray-500"
                      onClick={() => handleEditProduct(product)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="cursor-pointer color-primary"
                      onClick={() => handleDeleteProduct(product)}
                    >
                      delete
                    </Icon>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="add_circle_outline">
              Ops! Nenhum produto encontrado. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`Visualizar histórico do produto`}
        onClose={setIsDrawerHistoryOpen}
        isOpen={isDrawerHistoryOpen}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <ProductHistoryForm
          product={selectedProduct}
          handleInputChange={handleInputChange}
          onClose={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
        />
      </Drawer>

      <Drawer
        title={`${
          typeDrawer === "CREATE" ? "Cadastrar novo produto" : "Editar produto"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <ProductForm
          product={selectedProduct}
          handleInputChange={handleInputChange}
          fetchProducts={() => {
            queryClient.invalidateQueries(["products", currentPage]);
          }}
          onClose={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deleteProduct}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir esse produto?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default ProductsTable;
