import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Checkbox, Label } from "flowbite-react";
import useCEP from "../../hooks/useCEP";
import { useForm } from "react-hook-form";
import { CUSTOMERS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import CPFInput from "../inputs/CPFInput";
import PhoneInput from "../inputs/PhoneInput";
import { resetValuesForm } from "../../utils/utils";
import { HiInformationCircle } from "react-icons/hi";

const CustomerForm = ({
  customer,
  fetchCustomers,
  isDrawerOpen,
  onClose,
  type = "CREATE",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setKey] = useState(0);
  const [error, setError] = useState(null);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (type === "EDIT" && customer) {
      Object.keys(customer).forEach((key) => {
        setValue(key, customer[key]);
      });
      setKey((prev) => prev + 1);
    } else if (type === "CREATE") {
      resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
    }
    setError(null);
  }, [customer, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      if (type === "CREATE") {
        await axios.post(CUSTOMERS.GET_CUSTOMER, data);
      } else {
        await axios.put(`${CUSTOMERS.GET_CUSTOMER}/${customer.id}`, data);
      }

      onClose(false);

      fetchCustomers();
      if (type === "CREATE")
        resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
      setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  return (
    <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <Input
          type="text"
          label="Nome *"
          name="name"
          required={true}
          register={register}
          error={errors?.name && "Este campo é obrigatório"}
        />
      </div>
      <div className="mb-6">
        <PhoneInput
          type="text"
          label="Telefone *"
          name="phoneNumber"
          required={true}
          register={register}
          error={errors?.phoneNumber && "Este campo é obrigatório"}
        />
      </div>
      <div className="mb-6">
        <CPFInput
          type="text"
          label="Cpf"
          name="cpf"
          required={false}
          register={register}
          error={errors?.cpf && "Este campo é obrigatório"}
          value={watch("cpf") || ""}
        />
      </div>
      <div className="mb-6">
        <Input
          type="email"
          label="E-mail"
          name="email"
          required={false}
          register={register}
        />
      </div>
      <div className="mb-6 flex gap-2">
        <Input
          type="text"
          label="CEP"
          name="addressPostalCode"
          required={false}
          register={register}
        />
        <Button
          onClick={onClickSearchCEP}
          className="flex align-center whitespace-nowrap primary"
          style={{ width: "220px", paddingTop: "4px" }}
          isProcessing={loadingCEP}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
        >
          Buscar CEP
        </Button>
      </div>
      <div className="mb-6">
        <Input
          type="text"
          label="Endereço"
          name="address"
          required={false}
          register={register}
        />
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="text"
            label="Número"
            name="addressNumber"
            required={false}
            register={register}
          />
          <Input
            type="text"
            label="Bairro"
            name="addressNeighborhood"
            required={false}
            register={register}
          />
        </div>
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="text"
            label="Cidade"
            name="addressCity"
            required={false}
            register={register}
          />
        </div>
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="date"
            label="Data de nascimento"
            name="birthday"
            required={false}
            register={register}
          />
          <div className="w-full relative">
            <select
              id="gender"
              name="gender"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              {...register("gender")}
            >
              <option value="">Selecione o gênero</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
            <label
              htmlFor="gender"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Sexo
            </label>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <Input
          type="text"
          label="Observações"
          name="observation"
          required={false}
          register={register}
        />
      </div>

      <div className="flex items-center gap-2 mb-6">
        <Checkbox id="isVip" name="isVip" {...register("isVip")} />
        <Label htmlFor="isVip" className="flex">
          Cliente VIP?
        </Label>
      </div>

      <Button
        type="submit"
        className="w-full mb-48 md:mb-4 primary"
        isProcessing={isSubmitting}
        processingSpinner={
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        }
        disabled={isSubmitting}
      >
        {type === "CREATE" ? "Criar" : "Atualizar"} cliente
      </Button>
      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
          cliente
        </Alert>
      )}
    </form>
  );
};

export default CustomerForm;
