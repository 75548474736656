import React from "react";
import Loading from "./Loading";

const DrawerLoading = () => {
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0 bg-white z-10 opacity-80 flex h-full justify-center items-center">
      <Loading />
    </div>
  );
};

export default DrawerLoading;
