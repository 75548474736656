import { Dropdown } from "flowbite-react";
import React from "react";
import { IoMdNotifications } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { NOTIFICATIONS } from "../config/apiConfig";
import { useQuery, useQueryClient } from "react-query";
import axios from "../config/axiosInstance";
import moment from "moment";

const Notification = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    error: errorNotifications,
    data: notifications,
  } = useQuery(["notifications"], async () => {
    const { data } = await axios.get(`${NOTIFICATIONS.GET}`);

    return data.data;
  });

  const handleMarkAsRead = async () => {
    const { data } = await axios.put(`${NOTIFICATIONS.GET_ALL}`);
    queryClient.invalidateQueries(["notifications"]);
  };

  const renderAction = (statusId) => {
    switch (+statusId) {
      case -1:
        return <span className="text-red-600">cancelou</span>;
      case 0:
        return <span className="text-gray-400">deixou pendente</span>;
      case 1:
        return <span className="text-green-500">confirmou</span>;
    }
  };

  return (
    <Dropdown
      label={
        <div className="relative">
          <IoMdNotifications
            fontSize={25}
            className="focus:outline-none focus-visible:outline-none"
          />
          {notifications?.count > 0 && (
            <span className="absolute flex h-3 w-3 top-0 right-0">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"></span>
            </span>
          )}
        </div>
      }
      arrowIcon={false}
      inline
    >
      <Dropdown.Header className="bg-gray-200">
        <span className="block truncate text-sm font-medium font-semibold">
          Notificações ({notifications?.count})
        </span>
      </Dropdown.Header>
      {notifications?.count > 0 ? (
        notifications?.notifications.map((notification, index) => (
          <>
            <Dropdown.Item
              key={index}
              className="text-xs text-left bg-gray-100 font-semibold flex flex-col items-start"
            >
              <div>
                {notification?.user} {renderAction(notification.statusId)} o
                agendamento do profissional {notification?.Professional?.name}
                {notification?.Customer && (
                  <div>Cliente: {notification?.Customer.name}</div>
                )}
              </div>
              <div className="text-gray-500 text-2xs">
                {moment(notification?.createdAt).format("DD/MM/YY - HH:mm")}
              </div>
            </Dropdown.Item>
            <Dropdown.Divider className="bg-gray-300" />
          </>
        ))
      ) : (
        <Dropdown.Item className="flex justify-center gap-1 items-center">
          <span>Nenhuma nova notificação</span>
        </Dropdown.Item>
      )}

      {notifications?.count > 0 && (
        <Dropdown.Item
          className="text-red-500 flex justify-center gap-1 items-center bg-gray-200 font-semibold"
          onClick={handleMarkAsRead}
        >
          <span className="text-xs">Marcar todas como lidas</span>
          <IoEyeOutline fontSize={18} />
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};

export default Notification;
