import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { FiEye } from "react-icons/fi";
import { BiSort } from "react-icons/bi";
import { IoMdAdd, IoMdPerson, IoMdSearch } from "react-icons/io";
import Drawer from "../Drawer";
import { COMMANDS, PAYMENTS, PROFESSIONALS } from "../../config/apiConfig";
import { Badge, Button, Card, Pagination } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import EmptyTable from "./EmptyTable";
import moment from "moment";
import CommandForm from "../forms/CommandForm";
import { calculateDaysDiffCurrentDate } from "../../utils/dates";
import { useApplication } from "../../contexts/ApplicationContext";
import FilterToolbar from "../inputs/FilterToolbar";
import { formatCurrencyBRL } from "../../utils/utils";

const CommandsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderCommands, setOrderCommands] = useState("DESC");
  const [statusCommands, setStatusCommands] = useState(-1);
  const [dateCommand, setDateCommand] = useState("");
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const queryClient = useQueryClient();
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: commands,
  } = useQuery(
    [
      "commands",
      currentPage,
      orderCommands,
      statusCommands,
      dateCommand,
      nameParam,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${COMMANDS.GET_COMMANDS}?page=${currentPage}&order=${orderCommands}${
          statusCommands != -1 ? `&statusId=${statusCommands}` : ""
        }&startDate=${dateCommand}&endDate=${dateCommand}&searchQuery=${nameParam}&typeCommand=C`
      );
      setTotalPages(items.data.totalPages || 1);
      setTotalCount(items.data.totalCount || 0);

      return items.data.data;
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [nameParam]);

  const {
    isLoadingPaymentTaxes,
    errorPaymentTaxes,
    data: paymentTaxes,
  } = useQuery(["paymentTaxes"], async () => {
    const { data: items } = await axios.get(`${PAYMENTS.GET_PAYMENTS}`);

    return items.data.Payments.map((payment) => ({
      id: payment.PaymentCompany.PaymentId,
      taxPercentage: payment.PaymentCompany.taxPercentage,
    }));
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCommand((prevCommand) => ({
      ...prevCommand,
      [name]: value,
    }));
  };

  const handleAddCommand = () => {
    setSelectedCommand(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditCommand = (command) => {
    setTypeDrawer("EDIT");
    setSelectedCommand(command);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handledeleteCommand = (command) => {
    setSelectedCommand(command);
    setOpenModalDelete(true);
  };

  const deleteCommand = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${COMMANDS.GET_COMMAND}/${selectedCommand.id}`);
      queryClient.invalidateQueries(["commands", currentPage]);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onChangeFilter = ({ target }) => {
    setCurrentPage(1);
    setStatusCommands(target.value);
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  return (
    <>
      <div className="flex justify-start flex-col-reverse md:flex-row md:justify-between mb-1 mt-2 gap-2 items-start md:items-center">
        <FilterToolbar
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        >
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <FiEye className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0  appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={statusCommands}
              onChange={onChangeFilter}
            >
              <option selected value={-1}>
                Situação
              </option>
              <option value={0}>Pendentes</option>
              <option value={1}>Faturadas</option>
            </select>
          </div>
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <BiSort className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={orderCommands}
              onChange={({ target }) => setOrderCommands(target.value)}
            >
              <option selected value="DESC">
                Mais recentes
              </option>
              <option value="ASC">Mais antigas</option>
            </select>
          </div>

          <input
            type="date"
            value={dateCommand}
            onChange={(e) => setDateCommand(e.target.value)}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </FilterToolbar>
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddCommand}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Adicionar comanda</span>
        </Button>
      </div>
      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque pelo nome do cliente..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>

      {!isLoading ? (
        <>
          {totalCount ? (
            <div className="text-sm mt-5">
              Total de <strong>{totalCount}</strong> comandas
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          role="status"
          className="max-w-sm animate-pulse mt-5"
          style={{ height: "20px" }}
        >
          <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
        </div>
      )}

      {!isLoading ? (
        <>
          {commands.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
              {commands.map((command, index) => (
                <Card key={index} className="relative">
                  <div className="flex gap-3 items-center justify-between">
                    <h2 className="font-bold">
                      Comanda #{command.commandNumber}
                    </h2>
                    <div className="flex gap-2">
                      <div className="text-sm">
                        <Badge
                          className={`${
                            command?.statusId === 1
                              ? "bg-green-300 text-green-600"
                              : "bg-gray-300 text-gray-600"
                          }`}
                        >
                          {command?.statusId === 1 ? "Faturado" : "Pendente"}
                        </Badge>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-sm flex gap-2 items-center">
                      <span>Data: {moment(command?.date).format("DD/MM")}</span>
                      {command?.statusId === 0 &&
                        calculateDaysDiffCurrentDate(command.date) > 0 && (
                          <Badge color="failure">
                            +{calculateDaysDiffCurrentDate(command.date)} dias
                          </Badge>
                        )}
                    </div>
                    <div className="mt-2 text-sm truncate mr-16">
                      Cliente: {command.Customer?.name}
                    </div>
                    <div className="text-sm mt-2 flex gap-2 items-center">
                      <span>
                        Total {command.statusId !== 1 ? "estimado" : "pago"} :{" "}
                      </span>{" "}
                      <Badge
                        className="w-fit"
                        color={command.statusId !== 1 ? "gray" : "green"}
                      >
                        {formatCurrencyBRL.format(command?.totalPrice)}
                      </Badge>
                    </div>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8 items-center">
                    {command?.statusId !== 1 ? (
                      <Icon
                        fontSize="small"
                        className="cursor-pointer text-gray-500"
                        onClick={() => handleEditCommand(command)}
                      >
                        edit
                      </Icon>
                    ) : (
                      <FiEye
                        className="text-gray-500"
                        onClick={() => handleEditCommand(command)}
                      />
                    )}
                    <Icon
                      fontSize="small"
                      className="cursor-pointer color-primary"
                      onClick={() => handledeleteCommand(command)}
                    >
                      delete
                    </Icon>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="add_circle_outline">
              Ops! Nenhuma comanda encontrada. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${
          typeDrawer === "CREATE" ? "Cadastrar nova comanda" : `Editar comanda #${selectedCommand?.commandNumber}`
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <CommandForm
          command={selectedCommand}
          isLoadingCommand={isLoading}
          setCommand={setSelectedCommand}
          handleInputChange={handleInputChange}
          fetchCommands={() => {
            queryClient.invalidateQueries(["commands", currentPage]);
            queryClient.invalidateQueries(["products"]);
          }}
          onClose={setIsDrawerOpen}
          type={typeDrawer}
          isDrawerOpen={isDrawerOpen}
          paymentTaxes={paymentTaxes}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deleteCommand}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir essa comanda?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default CommandsTable;
