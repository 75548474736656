import React, { useState } from "react";
import { ADDITIONAL_PLANS } from "../../config/apiConfig";
import { Alert, Badge, Card } from "flowbite-react";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { useApplication } from "../../contexts/ApplicationContext";

const AdditionalPlansTable = () => {
  const { additionalPlansCompany, user } = useApplication();

  const {
    isLoading,
    error,
    data: additionalPlans,
  } = useQuery(["additionalPlans"], async () => {
    const { data: items } = await axios.get(`${ADDITIONAL_PLANS.GET_ALL}`);

    return items.data;
  });

  return (
    <div>
      <Alert color="green" withBorderAccent className="mb-5">
        Seu plano: <b>{user?.Company.planId}</b>
      </Alert>
      {!isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
          {additionalPlans?.length > 0 &&
            additionalPlans?.map((plan, index) => (
              <Card key={index} className="card-dashboard flex flex-col">
                <div className="font-semibold">{plan.name}</div>
                <div className="text-gray-600">{plan.description}</div>
                <div className="flex gap-1 items-center flex-wrap mb-3 text-sm font-semibold">
                  Inclusos nos planos:{" "}
                  {plan.includedPlans?.split(",").map((ip, index) => (
                    <Badge key={index} color="gray" className="w-fit">
                      {ip}
                    </Badge>
                  ))}
                </div>
                <div className="mt-auto">
                  <div className="text-center mb-2 font-semibold text-gray-600">
                    R$ {plan.price.toFixed(2).replace(".", ",")}/mês
                  </div>
                  <div className="flex justify-center">
                    {plan.includedPlans
                      ?.split(",")
                      .includes(user?.Company.planId) ? (
                      <div className="bg-green-500 w-full rounded-md text-center py-2 text-white">
                        Incluso no plano
                      </div>
                    ) : (
                      <>
                        {!additionalPlansCompany?.includes(plan.id) ? (
                          <a
                            href={plan.link}
                            target="_blank"
                            className="w-full bg-primary rounded-md py-2 text-center text-white"
                          >
                            Contratar agora
                          </a>
                        ) : (
                          <div className="bg-green-500 w-full rounded-md text-center py-2 text-white">
                            Contratado
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Card>
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdditionalPlansTable;
