import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import { CATEGORIES, PRODUCTS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, List, Tabs } from "flowbite-react";
import { useQuery } from "react-query";
import { AiOutlineLoading } from "react-icons/ai";
import SelectInput from "../inputs/SelectInput";
import { HiInformationCircle } from "react-icons/hi";
import moment from "moment";

const ProductForm = ({
  product,
  fetchProducts,
  isDrawerOpen,
  onClose,
  type = "CREATE",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [key, setKey] = useState(0);
  const [isEnabledHistory, setIsEnabledHistory] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();
  const {
    register: registerStock,
    handleSubmit: handleSubmitStock,
    reset: resetStock,
    formState: { errors: errorsStock },
    clearErrors: clearErrorsStock,
  } = useForm();

  const {
    isLoading,
    error,
    data: categories,
  } = useQuery(["categories"], async () => {
    const { data: items } = await axios.get(`${CATEGORIES.GET_CATEGORIES}`);
    return items.data.data;
  });

  const {
    isLoading: isLoadingProductOutputs,
    error: errorProductOutputs,
    data: productOutputs,
  } = useQuery(
    ["productOutputs", product?.id],
    async () => {
      const { data: items } = await axios.get(
        `${PRODUCTS.GET_OUTPUTS}/${product?.id}`
      );
      return items.data;
    },
    { enabled: isEnabledHistory }
  );

  useEffect(() => {
    reset();
    setErrorSubmit(null);

    if (type === "EDIT" && product) {
      Object.keys(product).forEach((key) => {
        setValue(key, product[key]);
      });
      setKey((prev) => prev + 1);
      setIsEnabledHistory(true);
    } else if (type === "CREATE") {
      reset();
    }
  }, [product, type, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (type === "CREATE") {
        await axios.post(PRODUCTS.GET_PRODUCT, data);
      } else {
        await axios.put(`${PRODUCTS.GET_PRODUCT}/${product.id}`, data);
      }

      onClose(false);

      fetchProducts();
      if (type === "CREATE") reset();
    } catch (error) {
      console.error(error);
      setErrorSubmit(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitStock = async (data) => {
    try {
      setIsSubmitting(true);

      await axios.put(`${PRODUCTS.STOCK}/${product.id}`, data);

      onClose(false);

      fetchProducts();
    } catch (error) {
      console.error(error);
      setErrorSubmit(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Tabs
        aria-label="Tabs with underline"
        variant="underline"
        ref={tabsRef}
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        <Tabs.Item active title="Produto">
          <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Input
                type="text"
                label="Nome do produto *"
                name="name"
                required={true}
                register={register}
                error={errors?.name && "Este campo é obrigatório"}
              />
            </div>
            <div className="mb-6">
              <Input
                type="number"
                label="Preço de compra *"
                name="pricePaid"
                required={true}
                register={register}
                error={errors?.priceSell && "Este campo é obrigatório"}
                step="0.01"
              />
            </div>
            <div className="mb-6">
              <Input
                type="number"
                label="Preço de venda *"
                name="priceSell"
                required={true}
                register={register}
                error={errors?.pricePaid && "Este campo é obrigatório"}
                step="0.01"
              />
            </div>
            <div className="mb-6">
              <select
                id="categoryId"
                name="categoryId"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                {...register("categoryId", { required: true })}
              >
                <option value="" selected>
                  Selecione a categoria
                </option>
                {!isLoading &&
                  categories.map((category, index) => (
                    <option key={index} value={`${category.id}`}>
                      {category.name}
                    </option>
                  ))}
              </select>
              {errors?.categoryId && (
                <small className="text-red-400">
                  Você precisa selecionar uma categoria!
                </small>
              )}
            </div>

            {type === "CREATE" && (
              <div className="mb-6">
                <Input
                  type="number"
                  label="Estoque atual"
                  name="stock"
                  required={false}
                  register={register}
                  defaultValue={0}
                  min={0}
                />
              </div>
            )}

            <div className="mb-6">
              <Input
                type="number"
                label="Estoque mínimo"
                name="minStock"
                required={false}
                register={register}
                defaultValue={0}
                min={0}
              />
              <small className="italic text-gray-500">
                Estabeleça um estoque mínimo para receber alertas quando o nível
                atual de estoque estiver próximo desse limite.
              </small>
            </div>

            <Button
              type="submit"
              className="w-full primary"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              {type === "CREATE" ? "Criar" : "Atualizar"} produto
            </Button>
          </form>
        </Tabs.Item>
        {type === "EDIT" && (
          <Tabs.Item title="Estoque">
            <form className="mb-6" onSubmit={handleSubmitStock(onSubmitStock)}>
              <div className="mb-6">
                <Input
                  type="number"
                  label="Entrada de estoque"
                  name="stock"
                  required={false}
                  register={registerStock}
                  defaultValue={0}
                  min={0}
                />
              </div>
              <Button
                type="submit"
                className="w-full primary"
                isProcessing={isSubmitting}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={isSubmitting}
              >
                Atualizar estoque
              </Button>
            </form>

            <h2 className="mt-6 font-semibold">Últimas saídas</h2>
            {!isLoadingProductOutputs ? (
              <>
                {productOutputs?.length > 0 ? (
                  <List
                    unstyled
                    className="w-full divide-y divide-gray-200 dark:divide-gray-700"
                  >
                    {productOutputs?.map((output, index) => (
                      <List.Item key={index} className="py-2 sm:py-3">
                        <div className="text-sm md:text-base">
                          Quantidade: {output.quantity}
                        </div>
                        <div className="text-sm md:text-base">
                          Data: {moment(output.updatedAt).format("DD/MM/yyyy")}
                        </div>
                        <div className="flex items-center gap-2 text-sm md:text-base">
                          <div> Profissional:</div>{" "}
                          <Badge className="w-fit">
                            {output.professionalName}
                          </Badge>
                        </div>
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <div className="mt-6">
                    Nenhuma saída deste produto até o momento.
                  </div>
                )}
              </>
            ) : (
              <div>Carregando histórico de saídas</div>
            )}
          </Tabs.Item>
        )}
      </Tabs>
      {errorSubmit && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {errorSubmit.config.method === "put" ? "atualizar" : "criar"}{" "}
          produto
        </Alert>
      )}
      <div className="mb-40"></div>
    </>
  );
};

export default ProductForm;
