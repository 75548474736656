import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const FabButton = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`visible md:hidden fab ${isOpen ? "is-open" : ""}`}>
      <button className="fab__toggle shadow-lg" onClick={handleToggleClick}>
        <FaPlus fontSize={20} />
      </button>
      <nav className="fab__nav">{children}</nav>
    </div>
  );
};

export default FabButton;
