import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import { CATEGORIES } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { Accordion, Alert, Button, List, Timeline } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiCheckCircle, HiInformationCircle } from "react-icons/hi";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

const CategoryForm = ({
  category,
  fetchCategories,
  onClose,
  type = "CREATE",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setKey] = useState(0);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
    setError(null);

    if (type === "EDIT" && category) {
      Object.keys(category).forEach((key) => {
        setValue(key, category[key]);
      });
      setKey((prev) => prev + 1);
    }
  }, [category]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      if (type === "CREATE") {
        await axios.post(CATEGORIES.GET_CATEGORY, data);
      } else {
        await axios.put(`${CATEGORIES.GET_CATEGORY}/${category.id}`, data);
      }

      onClose(false);

      fetchCategories();
      if (type === "CREATE") reset();
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <Input
            type="text"
            label="Nome *"
            name="name"
            required={true}
            register={register}
            error={errors?.name && "Este campo é obrigatório"}
          />
        </div>

        <Button
          type="submit"
          className="w-full primary shadow"
          isProcessing={isSubmitting}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
          disabled={isSubmitting}
        >
          {type === "CREATE" ? "Criar" : "Atualizar"} categoria
        </Button>
      </form>

      {type === "EDIT" && (
        <Accordion>
          <Accordion.Panel>
            <Accordion.Title>Serviços nesta categoria ({category.Services?.length})</Accordion.Title>
            <Accordion.Content>
              <List>
                {category.Services.map((service, index) => (
                  <List.Item icon={MdOutlineSubdirectoryArrowRight} key={index}>
                    {service.name}
                  </List.Item>
                ))}
              </List>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>Produtos nesta categoria ({category.Products?.length})</Accordion.Title>
            <Accordion.Content>
              <List>
                {category.Products.map((product, index) => (
                  <List.Item icon={MdOutlineSubdirectoryArrowRight} key={index}>
                    {product.name}
                  </List.Item>
                ))}
              </List>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      )}
      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
          categoria
        </Alert>
      )}
    </>
  );
};

export default CategoryForm;
