import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Card, Checkbox, Label } from "flowbite-react";
import { Controller, useForm } from "react-hook-form";
import {
  COMMANDS,
  CUSTOMERS,
  PACKAGES,
  PAYMENTS,
  PROFESSIONALS,
  SERVICES,
} from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import AutoCompleteInput from "../inputs/Autocomplete";
import { useQuery, useQueryClient } from "react-query";
import { MdOutlineDelete } from "react-icons/md";
import moment from "moment";
import CommandPaymentForm from "./CommandPaymentForm";
import { formatCurrencyBRL } from "../../utils/utils";

const PackageForm = ({
  packageObj,
  fetchPackages,
  isDrawerOpen,
  onClose,
  type = "CREATE",
  selectedProfessionalId = 0,
}) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [packageServices, setPackageServices] = useState([
    { serviceId: 0, quantity: 1, price: 0 },
  ]);

  const {
    isLoading: isLoadingCustomers,
    error: errorCustomers,
    data: customersPackages,
  } = useQuery(["customersPackages"], async () => {
    const { data: items } = await axios.get(
      `${CUSTOMERS.GET_CUSTOMERS}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  });

  const {
    isLoading: isLoadingProfessionals,
    error: errorProfessionals,
    data: professionals,
  } = useQuery(["professionals"], async () => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONALS}?itemsPerPage=${-1}&isActive=1`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  });

  const {
    isLoading: isLoadingServices,
    error: errorServices,
    data: services,
  } = useQuery(["services"], async () => {
    const { data: items } = await axios.get(
      `${SERVICES.GET_SERVICES}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
      price: item.price,
      typePrice: item.typePrice,
    }));
  });

  const {
    isLoadingPaymentTaxes,
    errorPaymentTaxes,
    data: paymentTaxes,
  } = useQuery(["paymentTaxes"], async () => {
    const { data: items } = await axios.get(`${PAYMENTS.GET_PAYMENTS}`);

    return items.data.Payments.map((payment) => ({
      id: payment.PaymentCompany.PaymentId,
      taxPercentage: payment.PaymentCompany.taxPercentage,
    }));
  });

  const {
    register,
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const servicesList = watch("services");

  useEffect(() => {
    reset();
    setError(null);
    setPackageServices([]);

    if (type === "EDIT" && packageObj) {
      Object.keys(packageObj).forEach((key) => {
        setValue(key, packageObj[key]);
      });

      if (
        packageObj &&
        packageObj?.Services &&
        Array.isArray(packageObj.Services)
      ) {
        packageObj.Services.forEach((service, index) => {
          setValue(`services[${index}].serviceId`, service.id);
          setValue(
            `services[${index}].quantity`,
            service.PackageService.quantity
          );

          setValue(`services[${index}].typePrice`, service?.typePrice);

          setValue(
            `services[${index}].priceUnit`,
            service.PackageService?.priceUnit || service?.price
          );

          setValue(
            `services[${index}].ProfessionalId`,
            service.PackageService?.ProfessionalId
          );

          setPackageServices((prev) => [
            ...prev,
            {
              serviceId: service.id,
              quantity: service.PackageService.quantity,
              priceUnit: service.PackageService?.priceUnit || 0,
              professionalId: service.PackageService?.ProfessionalId,
            },
          ]);
        });
      }
      setKey((prev) => prev + 1);
    } else {
      setValue("date", moment(new Date()).format("YYYY-MM-DD"));
      setPackageServices([]);
    }
  }, [packageObj, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      data.date = moment().format("YYYY-MM-DD");
      data.totalPrice = calculateTotalPricePackage();
      data.typeCommand = "P";

      if (data.discount === "") {
        delete data.discount;
      }

      setIsSubmitting(true);
      if (type === "CREATE") {
        await axios.post(PACKAGES.GET_PACKAGE, data);
      } else {
        await axios.put(`${PACKAGES.GET_PACKAGE}/${packageObj.id}`, data);
      }

      onClose(false);

      fetchPackages();
      if (type === "CREATE") setError(null);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddService = () => {
    let sizeArray = packageServices.length;

    setPackageServices((prev) => [
      ...prev,
      {
        serviceId: 0,
        quantity: 1,
        priceUnit: 0,
        ProfessionalId: selectedProfessionalId,
      },
    ]);

    if (selectedProfessionalId !== 0) {
      setValue(`services[${sizeArray}].ProfessionalId`, selectedProfessionalId);
    }
  };

  const handleDeleteService = (indexToRemove) => {
    setPackageServices((prev) =>
      prev?.filter((_, index) => index !== indexToRemove)
    );

    setValue(
      `services`,
      servicesList?.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleFinishPayment = () => {
    setOpenModalPayment(true);
  };

  const calculateTotalPricePackage = () => {
    let sumServices = watch("services")?.reduce((accumulator, currentValue) => {
      return accumulator + +currentValue.priceUnit * +currentValue.quantity;
    }, 0);

    return sumServices - +watch("discount");
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label className="inline-flex items-center me-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              name="hasExpirationDate"
              {...register("hasExpirationDate")}
              disabled={type === "EDIT"}
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              O pacote expira?
            </span>
          </label>
        </div>
        {watch("hasExpirationDate") && (
          <div className="mb-6">
            <Input
              type="date"
              label="Data de expiração"
              name="expirationDate"
              required={true}
              register={register}
              error={errors.expirationDate && "Este campo é obrigatório"}
              disabled={type === "EDIT"}
            />
            {errors && (
              <small className="text-red-400">{errors.date?.message}</small>
            )}
          </div>
        )}

        <div className="mb-6">
          <Controller
            name="customerId"
            control={control}
            rules={{ required: "Este campo é obrigatório" }}
            render={({ field }) => (
              <AutoCompleteInput
                label="Selecione o cliente *"
                disabled={type === "EDIT"}
                options={customersPackages}
                onChange={(selectedOption) =>
                  field.onChange(selectedOption.value)
                }
                placeholder=""
                isLoading={isLoadingCustomers}
                value={field.value}
                noOptionsMessage={
                  <div>
                    <span className="mb-2 block">
                      Nenhum cliente encontrado.
                    </span>
                  </div>
                }
              />
            )}
          />
          {errors && (
            <small className="text-red-400">{errors.customerId?.message}</small>
          )}
        </div>

        <div className="mt-6">
          <h3 className="mb-3 font-semibold">
            Serviços ({packageServices.length})
          </h3>
          {packageServices.map((field, index) => (
            <Card className="mb-3" key={index}>
              <div className="flex gap-2 flex-col md:flex-row">
                <Controller
                  name={`services[${index}].serviceId`}
                  control={control}
                  rules={{ required: "Este campo é obrigatório" }}
                  render={({ field }) => (
                    <AutoCompleteInput
                      label="Serviço *"
                      options={services}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value);
                        const service = services.find(
                          (service) => selectedOption.value == service.value
                        );

                        setValue(`services[${index}].priceUnit`, service.price);
                        setValue(
                          `services[${index}].typePrice`,
                          service.typePrice
                        );
                      }}
                      placeholder=""
                      isLoading={isLoadingServices}
                      value={watch(`services[${index}].serviceId`)}
                      disabled={type === "EDIT"}
                    />
                  )}
                />
                <div className="flex gap-2 w-full items-center mt-2 md:mt-0">
                  <Input
                    className="w-full"
                    type="number"
                    register={register}
                    label="Quantidade"
                    name={`services[${index}].quantity`}
                    value={watch(`services[${index}].quantity`)}
                    defaultValue={1}
                    disabled={type === "EDIT"}
                  />
                  <div className="text-sm w-full">
                    <Input
                      className="w-full"
                      type="number"
                      register={register}
                      label="Preço Unit."
                      name={`services[${index}].priceUnit`}
                      value={watch(`services[${index}].priceUnit`)}
                      defaultValue={0}
                      disabled={type === "EDIT"}
                      step="0.01"
                    />
                  </div>
                  {packageServices.length > 0 && (
                    <Button
                      className="bg-gray-500 flex items-center justify-center"
                      onClick={() => handleDeleteService(index)}
                      disabled={type === "EDIT"}
                    >
                      <MdOutlineDelete className="text-lg h-7" />
                    </Button>
                  )}
                </div>
              </div>
              <div>
                <Controller
                  name={`services[${index}].ProfessionalId`}
                  control={control}
                  rules={{ required: "Este campo é obrigatório" }}
                  render={({ field }) => (
                    <AutoCompleteInput
                      label="Selecione o profissional *"
                      options={professionals}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption.value)
                      }
                      placeholder=""
                      isLoading={isLoadingProfessionals}
                      value={field.value}
                      disabled={type === "EDIT"}
                    />
                  )}
                />
                {errors?.services?.[index]?.ProfessionalId && (
                  <small className="text-red-400">
                    {errors.services[index].ProfessionalId.message}
                  </small>
                )}
              </div>
            </Card>
          ))}
        </div>

        {type === "CREATE" && (
          <div className="flex mt-3 mb-6">
            <Button
              className="primary shadow"
              size="xs"
              onClick={handleAddService}
            >
              Adicionar serviço
            </Button>
          </div>
        )}

        <div className="flex gap-4 mt-6">
          <div className="mb-6">
            <Input
              type="number"
              label="Descontos"
              name="discount"
              required={false}
              register={register}
              step="0.01"
              disabled={type === "EDIT"}
            />
          </div>

          <div className="font-semibold">
            <div>Preço Total:</div>
            <div>
              {formatCurrencyBRL.format(calculateTotalPricePackage() || 0)}
            </div>
          </div>
        </div>

        <div className="mb-6">
          <Input
            type="text"
            label="Observações"
            name="observation"
            required={false}
            register={register}
            disabled={type === "EDIT"}
          />
        </div>

        {type === "CREATE" && (
          <Button
            type="submit"
            className="w-full mb-48 md:mb-4 primary"
            isProcessing={isSubmitting}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            disabled={isSubmitting || packageServices.length < 1}
          >
            Criar pacote
          </Button>
        )}
        {packageObj?.Command?.statusId === 0 && (
          <Button
            className="w-full mb-48 md:mb-4 bg-green-500"
            onClick={handleFinishPayment}
          >
            Faturar pacote
          </Button>
        )}

        {error && (
          <Alert color="failure" icon={HiInformationCircle}>
            Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
            pacote
          </Alert>
        )}
      </form>

    <CommandPaymentForm
        command={{ id: packageObj?.commandId }}
        paymentTaxes={paymentTaxes}
        fetchCommands={fetchPackages}
        openModal={openModalPayment}
        setOpenModal={setOpenModalPayment}
        setOpenParentModal={onClose}
        setValue={setValue}
        register={register}
        watch={watch}
        totalPrice={calculateTotalPricePackage()}
      />
    </>
  );
};

export default PackageForm;
