import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import AppRouter from "./routes/AppRouter";
import { PermissionProvider } from "./contexts/ApplicationContext";

const removeLoadingSpinner = () => {
  const loadingContainer = document.getElementById("loading-container");
  if (loadingContainer) {
    loadingContainer.style.display = "none";
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: 240000, //4 minutos para fazer outro refetch
      refetchIntervalInBackground: true,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <PermissionProvider>
      <AppRouter />
    </PermissionProvider>
  </QueryClientProvider>
);

window.addEventListener("load", removeLoadingSpinner);
