import { Badge, Button, Card, List, Tabs, Timeline } from "flowbite-react";
import { CUSTOMERS, FORMS, PACKAGES } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import CustomerInfosFormForm from "./CustomerInfosFormForm";
import Drawer from "../Drawer";
import { formatCurrencyBRL } from "../../utils/utils";
import Loading from "../Loading";
import EmptyTable from "../tables/EmptyTable";
import { useApplication } from "../../contexts/ApplicationContext";

const CustomerInfosForm = ({
  customer,
  isDrawerOpen,
  onClose,
  shouldFetchCustomerInfos,
}) => {
  const { userPermission } = useApplication();
  const [isDrawerOpenForm, setIsDrawerOpenForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const {
    isLoadingCustomerInfos,
    errorCustomerInfos,
    data: customerInfos,
  } = useQuery(
    ["customerInfos", customer, isDrawerOpen],
    async () => {
      const { data: items } = await axios.get(
        `${CUSTOMERS.GET_CUSTOMER}/infos/${customer?.id}`
      );

      return items.data;
    },
    {
      enabled: activeTab === 0,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  const {
    isLoadingCustomerForms,
    errorCustomerForms,
    data: customerForms,
  } = useQuery(
    ["customerForms", customer, isDrawerOpen],
    async () => {
      const { data: items } = await axios.get(
        `${FORMS.GET_FORMS}?customerId=${customer?.id}`
      );

      return items.data;
    },
    {
      enabled: activeTab === 1,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  const {
    isLoadingCustomerPackages,
    errorCustomerPackages,
    data: customerPackages,
  } = useQuery(
    ["customerPackages", customer, isDrawerOpen],
    async () => {
      const { data: items } = await axios.get(
        `${PACKAGES.GET_BY_CUSTOMER}/${customer?.id}`
      );

      return items.data;
    },
    {
      enabled: activeTab === 2,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  const handleClickSelectForm = (form) => {
    setSelectedForm(form);
    setIsDrawerOpenForm(true);
  };

  return (
    <div className="flex flex-col gap-2">
      <Tabs
        aria-label="Tabs with underline"
        variant="underline"
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        <Tabs.Item active title="Agendamento">
          {!isLoadingCustomerInfos ? (
            <>
              <div className="text-sm md:text-base font-semibold mb-3">
                Data da última visita
              </div>
              {customerInfos?.lastAppointment ? (
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <div className="text-sm md:text-base">
                      Data:{" "}
                      {moment(customerInfos?.lastAppointment?.date).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      |{" "}
                      {moment(
                        customerInfos?.lastAppointment?.startHour,
                        "HH:mm:ss"
                      ).format("HH:mm")}
                    </div>
                    <Badge className="w-fit" color="pink">
                      {moment().diff(
                        moment(customerInfos?.lastAppointment?.date),
                        "days"
                      )}{" "}
                      dias atrás
                    </Badge>
                  </div>

                  <div className="mt-1 text-sm md:text-base flex gap-2 items-center mb-2">
                    <div>Serviços realizados:</div>
                    <div className="flex gap-1">
                      {customerInfos?.lastAppointment?.Services?.map(
                        (service, index) => (
                          <Badge key={index} color="gray">
                            {service.name}
                          </Badge>
                        )
                      )}
                    </div>
                  </div>
                  <div className="mt-1 text-sm md:text-base">
                    Profissional:{" "}
                    {customerInfos?.lastAppointment?.Professional?.name}
                  </div>
                </div>
              ) : (
                <div className="text-sm md:text-base">
                  Este cliente ainda não realizou nenhum serviço.
                </div>
              )}

              <div className="text-sm md:text-base font-semibold mt-6 mb-3">
                Próximos agendamentos
              </div>
              {customerInfos?.futureAppointments &&
              customerInfos.futureAppointments.length > 0 ? (
                <Timeline>
                  {customerInfos.futureAppointments.map(
                    (futureAppointment, index) => {
                      return (
                        <Timeline.Item key={index}>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs">
                              {moment(futureAppointment.date).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              |{" "}
                              {moment(
                                futureAppointment?.startHour,
                                "HH:mm:ss"
                              ).format("HH:mm")}
                            </Timeline.Time>
                            <Timeline.Title className="text-sm">
                              <div>
                                Serviço:{" "}
                                {futureAppointment?.Services?.map(
                                  (service) => service.name
                                ).join(" + ")}
                              </div>
                              <div className="mt-1">
                                Profissional:{" "}
                                {futureAppointment?.Professional?.name}
                              </div>
                            </Timeline.Title>
                          </Timeline.Content>
                        </Timeline.Item>
                      );
                    }
                  )}
                </Timeline>
              ) : (
                <div className="text-sm md:text-base">
                  Nenhum serviço agendado.
                </div>
              )}
              {userPermission === "ADMIN" && (
                <>
                  {" "}
                  <div className="text-sm md:text-base font-semibold mt-6 mb-3">
                    Total gasto
                  </div>
                  <div className="bg-green-500 w-fit rounded px-3 py-2 font-bold text-white shadow">
                    {formatCurrencyBRL.format(customerInfos?.totalSpent || 0)}
                  </div>
                </>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Tabs.Item>
        <Tabs.Item title="Formulário">
          {!isLoadingCustomerForms ? (
            <>
              {customerForms?.length > 0 ? (
                customerForms?.map((form, index) => (
                  <div
                    key={index}
                    className="rounded-md bg-gray-100 p-2 shadow-sm mb-2 flex justify-between items-center"
                  >
                    <div>
                      <div className="text-xs md:text-sm text-gray-700 mb-1">
                        Data:{" "}
                        {moment(form.createdAt).format("DD/MM/YYYY HH:MM")}
                      </div>
                      <div className="text-sm md:text-md font-semibold">
                        Nome: {form.Record.name}
                      </div>
                    </div>
                    <div>
                      <Button
                        className="primary"
                        onClick={() => handleClickSelectForm(form)}
                      >
                        <FiEye />
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyTable icon="search_off">
                  <span className="font-semibold">
                    Nenhum formulário cadastrado para este cliente.
                  </span>
                </EmptyTable>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Tabs.Item>
        <Tabs.Item title="Pacote">
          {!isLoadingCustomerPackages ? (
            <>
              {customerPackages?.length > 0 ? (
                customerPackages?.map((packageObj, index) => (
                  <Card key={index} className="mb-4">
                    <div className="flex justify-between">
                      <div>
                        <div className="text-xs md:text-sm text-gray-700 mb-1">
                          Data de pagamento:{" "}
                          {moment(packageObj.createdAt).format("DD/MM/YYYY")}
                        </div>
                        <div className="text-xs md:text-sm text-gray-700 mb-1">
                          {packageObj.hasExpirationDate
                            ? `Data de expiração: ${moment(
                                packageObj.expirationDate
                              ).format("DD/MM/YYYY")}`
                            : "Não expira"}
                        </div>
                      </div>

                      {moment(packageObj.createdAt).diff(moment(), "days") <
                      0 ? (
                        <Badge className="w-fit" color="gray">
                          Expirado
                        </Badge>
                      ) : (
                        <Badge className="w-fit" color="green">
                          Ativo
                        </Badge>
                      )}
                    </div>
                    <List>
                      {packageObj?.Services?.map((service, index) => (
                        <List.Item
                          key={index}
                          className="flex justify-start flex-col md:flex-row md:justify-between"
                        >
                          <div className="font-semibold">{service.name}</div>
                          <div className="flex gap-2 ">
                            <div className="flex gap-2">
                              Saldo:{" "}
                              <div className="font-semibold">
                                {service.PackageService.quantity -
                                  service.PackageService.quantityUsed}
                              </div>
                            </div>
                            <div className="flex gap-2">
                              Utilizado:{" "}
                              <div className="font-semibold">
                                {service.PackageService.quantityUsed}
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  </Card>
                ))
              ) : (
                <EmptyTable icon="search_off">
                  <span className="font-semibold">
                    Nenhum pacote cadastrado para este cliente.
                  </span>
                </EmptyTable>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Tabs.Item>
      </Tabs>
      <Drawer
        title="Visualizar formulário"
        onClose={setIsDrawerOpenForm}
        isOpen={isDrawerOpenForm}
        className="w-full md:w-3/6 pb-36"
      >
        <CustomerInfosFormForm form={selectedForm} />
      </Drawer>
    </div>
  );
};

export default CustomerInfosForm;
