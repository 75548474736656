import { useEffect, useState } from "react";
import { permissionChecker } from "../utils/permissions";
import { useApplication } from "../contexts/ApplicationContext";
import { Checkbox } from "flowbite-react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const CalendarProfessionalLabels = ({
  appointments,
  selectedProfessionals,
  setSelectedProfessionals,
}) => {
  const { userPermission } = useApplication();
  const [professionalLabels, setProfessionalLabels] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (permissionChecker(["USER_CALENDARS"], userPermission)) {
      const professionalIds = new Set();
      const professionalLabels = appointments.filter((appointment) => {
        if (professionalIds.has(appointment.professionalId)) {
          return false;
        }
        professionalIds.add(appointment.professionalId);
        return true;
      });

      setProfessionalLabels(professionalLabels);

      const newSelectedProfessionals = professionalLabels.map(
        (professionalLabel) => professionalLabel.Professional.id
      );
      setSelectedProfessionals(newSelectedProfessionals);
    }
  }, [appointments]);

  const handleProfessionalSelection = (professionalId) => {
    setSelectedProfessionals((prevSelected) => {
      if (prevSelected.includes(professionalId)) {
        return prevSelected.filter((id) => id !== professionalId);
      } else {
        return [...prevSelected, professionalId];
      }
    });
  };

  return (
    <>
      {professionalLabels.length > 0 && (
        <div className="flex flex-col md:flex-row gap-4 flex-wrap mt-5 mb-3 p-2 bg-white rounded-md shadow-md w-full md:w-fit">
          <div
            className="font-semibold flex gap-2 items-center justify-between professionals-filter-header cursor-pointer"
            onClick={toggleExpansion}
          >
            <span>Filtro de profissionais:</span>
            {isExpanded ? (
              <IoIosArrowUp className="block md:hidden" />
            ) : (
              <IoIosArrowDown className="block md:hidden" />
            )}
          </div>
          <div
            className={`flex flex-col md:flex-row gap-4 flex-wrap professionals-filter-body ${
              isExpanded ? "flex" : "hidden"
            } md:flex`}
          >
            {professionalLabels.map((label, index) => (
              <div key={index} className="flex gap-2 items-center">
                <Checkbox
                  style={{ backgroundColor: label.Professional.colorSchedule }}
                  value={label.Professional.id}
                  onChange={() =>
                    handleProfessionalSelection(label.Professional.id)
                  }
                  checked={selectedProfessionals.includes(
                    label.Professional.id
                  )}
                />
                <div
                  className="font-bold"
                  style={{ color: label.Professional.colorSchedule }}
                >
                  {label.Professional.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarProfessionalLabels;
