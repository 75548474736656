import { Checkbox, Label, Radio, TextInput } from "flowbite-react";

const CustomerInfosFormForm = ({ form }) => {
  return (
    <div>
      {form?.form?.map((field, index) => (
        <div key={index}>
          <div className="mb-5">
            {(field.fieldType === "text" || field.fieldType === "date") && (
              <>
                <div className="mb-2 block">
                  <Label
                    htmlFor={`field-${index}`}
                    value={`${field.fieldName}`}
                  />
                </div>
                <TextInput
                  id={`field-${index}`}
                  type={field.fieldType}
                  required
                  disabled
                  value={field.value[0]}
                />
              </>
            )}

            {field.fieldType === "radio" && (
              <>
                <div className="mb-2 block">
                  <Label
                    htmlFor={`field-${index}`}
                    value={`${field.fieldName}`}
                  />
                </div>
                {field.options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex items-center gap-2 mb-2"
                  >
                    <Radio
                      name={`options-${index}`}
                      disabled
                      checked={option === field.value[0]}
                    />
                    <Label value={`${option}`} />
                  </div>
                ))}
              </>
            )}

            {field.fieldType === "checkbox" && (
              <>
                <div className="mb-2 block">
                  <Label
                    htmlFor={`field-${index}`}
                    value={`${field.fieldName}`}
                  />
                </div>
                {field.options.map((option, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <Checkbox
                      name={`options-${index}`}
                      disabled
                      checked={field.value.includes(option)}
                    />
                    <Label value={`${option}`} />
                  </div>
                ))}
              </>
            )}

            {field.fieldType === "text-only" && (
              <small>{field.fieldName}</small>
            )}

            {field.fieldType === "title" && (
              <h3 className="font-semibold">{field.fieldName}</h3>
            )}
          </div>
        </div>
      ))}
      {form?.Record?.hasOnlineSignature && (
        <div className="border-b border-gray-400">
          <div>Assinatura do cliente:</div>
          <img src={`${form?.customerSignature}`} />
        </div>
      )}
    </div>
  );
};

export default CustomerInfosFormForm;
