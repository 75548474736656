import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { CATEGORIES, SERVICES } from "../../config/apiConfig";
import { Button, Card, Modal, Pagination } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import ServiceForm from "../forms/ServiceForm";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import { convertMinutesToHours, formatCurrencyBRL } from "../../utils/utils";
import EmptyTable from "./EmptyTable";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import { MdCategory } from "react-icons/md";
import { FaClock, FaDollarSign } from "react-icons/fa";
import FilterToolbar from "../inputs/FilterToolbar";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import ModalServiceDetails from "../modals/ModalServiceDetails";
import { FiEye } from "react-icons/fi";

const ServicesTable = () => {
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const queryClient = useQueryClient();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [priceServiceFilter, setPriceServiceFilter] = useState("");
  const [timeServiceFilter, setTimeServiceFilter] = useState("");
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState("");
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isOpenModalCategories, setOpenModalCategories] = useState(false);
  const [isOpenModalServiceDetails, setOpenModalServiceDetails] =
    useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");

  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: services,
  } = useQuery(
    [
      "services",
      currentPage,
      priceServiceFilter,
      timeServiceFilter,
      selectedCategoryFilter,
      nameParam,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${SERVICES.GET_SERVICES}?page=${currentPage}&categoryId=${selectedCategoryFilter}&price=${priceServiceFilter}&durationMinutes=${timeServiceFilter}&name=${searchName}`
      );
      setTotalPages(items.data.totalPages || 1);
      setTotalCount(items.data.totalCount || 0);

      return items.data.data;
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [nameParam]);

  const {
    isLoadingCategoriesFilter,
    errorCategoriesFilter,
    data: categoriesFilter,
  } = useQuery(["categoriesFilter"], async () => {
    const { data: items } = await axios.get(
      `${CATEGORIES.GET_CATEGORIES}?itemsPerPage=${-1}`
    );

    return items.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleAddService = () => {
    setSelectedService(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditService = (customer) => {
    setTypeDrawer("EDIT");
    setSelectedService(customer);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteService = (customer) => {
    setSelectedService(customer);
    setOpenModalDelete(true);
  };

  const deleteService = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${SERVICES.GET_SERVICE}/${selectedService.id}`);
      queryClient.invalidateQueries(["services"], currentPage);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  useEffect(() => {
    if (
      categoriesFilter?.length === 0 &&
      sessionStorage.getItem("showModalCategories") !== "true"
    ) {
      setOpenModalCategories(true);
      sessionStorage.setItem("showModalCategories", "true");
    }
  }, [categoriesFilter]);

  return (
    <>
      <div className="flex justify-between mb-1 mt-2 gap-2">
        <FilterToolbar
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        >
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <FaDollarSign className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0  appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={priceServiceFilter}
              onChange={(e) => setPriceServiceFilter(e.target.value)}
            >
              <option selected value={""}>
                Por preço
              </option>
              <option value="DESC">Mais caros</option>
              <option value="ASC">Mais baratos</option>
            </select>
          </div>
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <FaClock className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={timeServiceFilter}
              onChange={({ target }) => setTimeServiceFilter(target.value)}
            >
              <option selected value="">
                Por duração
              </option>
              <option value="DESC">Mais demorados</option>
              <option value="ASC">Mais rápidos</option>
            </select>
          </div>
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <MdCategory className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedCategoryFilter}
              onChange={({ target }) => setSelectedCategoryFilter(target.value)}
            >
              <option selected value={""}>
                Por categoria
              </option>
              {categoriesFilter?.map((category) => (
                <option key={category.id} selected value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </FilterToolbar>
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddService}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Adicionar serviço</span>
        </Button>
      </div>

      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome do serviço..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>

      {!isLoading ? (
        <>
          {totalCount ? (
            <div className="text-sm mt-5">
              Total de <strong>{totalCount}</strong> serviços
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          role="status"
          className="max-w-sm animate-pulse mt-5"
          style={{ height: "20px" }}
        >
          <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
        </div>
      )}

      {!isLoading ? (
        <>
          {services.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4 mt-4">
              {services.map((service, index) => (
                <Card key={index} className="relative">
                  <div className="flex gap-3 items-center">
                    {!service?.imageUrl ? (
                      <div
                        className="flex items-center justify-center"
                        style={{
                          height: "40px",
                          width: "40px",
                          fontSize: "18px",
                          color: "black",
                          borderRadius: "50%",
                          minWidth: "40px",
                          background: "var(--primary)",
                        }}
                      >
                        <Icon className="text-white" fontSize="small">
                          design_services
                        </Icon>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={service?.imageUrl}
                          className="rounded-full"
                          style={{
                            objectFit: "cover",
                            width: "40px",
                            minWidth: "40px",
                            height: "40px",
                          }}
                        />
                      </div>
                    )}
                    <h2>{service.name}</h2>
                  </div>
                  <div className="flex gap-1 text-gray-500">
                    <Icon fontSize="small">paid</Icon>
                    <span className="text-sm">
                      {formatCurrencyBRL.format(service.price)}
                    </span>
                  </div>
                  <div className="flex gap-1 text-gray-500">
                    <Icon fontSize="small">access_time</Icon>
                    <span className="text-sm">
                      Tempo: {convertMinutesToHours(service.durationMinutes)}h
                    </span>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8">
                    <FiEye
                      className="text-gray-500 cursor-pointer"
                      onClick={() => {
                        setSelectedService(service);
                        setOpenModalServiceDetails(true);
                      }}
                    />

                    <Icon
                      fontSize="small"
                      className="cursor-pointer text-gray-500"
                      onClick={() => handleEditService(service)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="cursor-pointer color-primary"
                      onClick={() => handleDeleteService(service)}
                    >
                      delete
                    </Icon>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="add_circle_outline">
              Ops! Nenhum serviço encontrado. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${
          typeDrawer === "CREATE" ? "Cadastrar novo serviço" : "Editar serviço"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <ServiceForm
          service={selectedService}
          handleInputChange={handleInputChange}
          fetchServices={() =>
            queryClient.invalidateQueries(["services", currentPage])
          }
          onClose={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deleteService}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir esse serviço?"
        isLoading={isLoadingDelete}
      />
      <Modal
        show={isOpenModalCategories}
        size="md"
        onClose={() => setOpenModalCategories(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
              Para cadastrar um serviço, é necessário criar uma categoria antes.{" "}
              <br />
              <b>Deseja cadastrar uma categoria agora?</b>
            </h3>
            <div className="flex justify-center gap-2">
              <Link
                to="/categories"
                className="bg-primary rounded-md flex justify-center items-center text-white px-3 text-sm"
              >
                <span>Cadastrar</span>
              </Link>
              <Button
                color="gray"
                onClick={() => setOpenModalCategories(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalServiceDetails
        isOpen={isOpenModalServiceDetails}
        setOpenModal={setOpenModalServiceDetails}
        service={selectedService}
      />
    </>
  );
};

export default ServicesTable;
