const TextAreaInput = ({
  className,
  defaultValue,
  error,
  label,
  name,
  value,
  required = false,
  type = "text",
  register,
  readOnly = false,
  disabled = false,
  placeholder = "",
  ...props
}) => {
  return (
    <>
      <div className={`w-full relative ${className}`}>
        <textarea
          type={type}
          id={name}
          className={`block px-2.5 pb-3.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 focus:border-red-800 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 peer `}
          placeholder={placeholder}
          {...register(name, { required })}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly}
          disabled={disabled}
          {...props}
          rows={4}
          maxlength="250"
        />
        <label
          htmlFor={name}
          className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-1 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-8 peer-placeholder-shown:top-5 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {label}
        </label>
      </div>
      {error && <small className="text-red-400">{error}</small>}
    </>
  );
};

export default TextAreaInput;
