import { ADDITIONAL_PLANS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Card, Spinner } from "flowbite-react";
import { MdCelebration } from "react-icons/md";

const SuccessPaymentPlanAdditionalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = () => {
    const params = new URLSearchParams(location.search);

    return params.get("planId");
  };

  const {
    isLoading,
    error,
    data: additionalPlan,
  } = useQuery(["additionalPlan"], async () => {
    const { data: item } = await axios.get(
      `${ADDITIONAL_PLANS.GET_ONE}/${getQueryParam()}`
    );

    return item.data;
  });

  useEffect(() => {
    if (!getQueryParam()) navigate("/additional-plans");
  }, []);

  return (
    <div>
      {!error ? (
        <>
          {!isLoading ? (
            <Card>
              <div className="flex flex-col items-center">
                <MdCelebration fontSize={30} className="text-green-500" />
                <div className="mt-2 font-semibold">
                  Parabéns! Você acabou de contratar o plano adicional!
                </div>
                <Badge size="xl" color="green" className="font-semibold mt-2">
                  {additionalPlan?.name}
                </Badge>
                <div className="text-center mt-4">
                  Em alguns minutos, essa funcionalidade estará disponível para
                  você! <br />
                  Caso tenha alguma dúvida para a utilização, basta entrar em
                  contato com a nossa equipe de suporte.
                </div>
              </div>
            </Card>
          ) : (
            <Card>
              <div className="flex justify-center my-4">
                <Spinner color="failure" aria-label="Failure spinner example" />
              </div>
            </Card>
          )}
        </>
      ) : (
        <Card>Ops, aconteceu algum erro com essa página!</Card>
      )}
    </div>
  );
};

export default SuccessPaymentPlanAdditionalPage;
