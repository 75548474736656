import Sidebar from "../components/Sidebar";

const Root = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div className="px-4 pt-4 pb-10 sm:ml-64 app-container">
        {children}
        <div className="white-container"></div>
      </div>
    </>
  );
};

export default Root;
