import { Badge, Button, Card, Modal, Pagination } from "flowbite-react";
import { CUSTOMERS, RECORDS } from "../../config/apiConfig";
import RecordForm from "../forms/RecordForm";
import { Icon } from "@mui/material";
import EmptyTable from "./EmptyTable";
import CardLoading from "../CardLoading";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "../../config/axiosInstance";
import Drawer from "../Drawer";
import { IoMdAdd } from "react-icons/io";
import ModalDelete from "../modals/ModalDelete";
import { TiDocumentText } from "react-icons/ti";
import { useApplication } from "../../contexts/ApplicationContext";
import { MdContentCopy } from "react-icons/md";
import AutoCompleteInput from "../inputs/Autocomplete";
import ModalConfirmAdditionalPlan from "../modals/ModalConfirmAdditionalPlan";

const RecordsTable = () => {
  const { user } = useApplication();
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const [openModalShareForm, setOpenModalShareForm] = useState(false);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: records,
  } = useQuery(["records", currentPage], async () => {
    const { data: items } = await axios.get(
      `${RECORDS.GET_RECORDS}?page=${currentPage}`
    );
    setTotalPages(items.data.totalPages || 1);
    return items.data.data;
  });
  
  //TODO: Fazer a busca apenas no momento que clicar em abrir o modal
  const {
    isLoading: isLoadingCustomers,
    error: errorCustomers,
    data: customers,
  } = useQuery(["customers"], async () => {
    const { data: items } = await axios.get(
      `${CUSTOMERS.GET_CUSTOMERS}?itemsPerPage=${-1}`
    );

    return items.data.data.map((item) => ({
      label: item.name,
      value: item.id,
      phoneNumber: item.phoneNumber,
    }));
  });

  const handleAddRecord = () => {
    setSelectedRecord(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditRecord = (record) => {
    setTypeDrawer("EDIT");
    setSelectedRecord(record);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteRecord = (record) => {
    setSelectedRecord(record);
    setOpenModalDelete(true);
  };

  const deleteRecord = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${RECORDS.GET_RECORD}/${selectedRecord.id}`);

      queryClient.invalidateQueries(["records", currentPage]);
      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleModalShareForm = (recordId) => {
    setOpenModalShareForm(true);
    setSelectedRecordId(recordId);
  };

  const handleCopyClipboard = (url) => {
    setTimeout(() => setIsLinkCopied(false), 2000);

    navigator.clipboard.writeText(url).then(
      () => {
        setIsLinkCopied(true);
      },
      (err) => {
        console.error("Erro ao copiar o texto: ", err);
      }
    );
  };

  return (
    <>
      <div className="flex justify-end mb-3 mt-2">
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddRecord}
        >
          <IoMdAdd className="block md:hidden text-2xl" />

          <span className="hidden md:block">Adicionar formulário</span>
        </Button>
      </div>
      {!isLoading ? (
        <>
          {records.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
              {records.map((record, index) => (
                <Card key={index} className="relative pb-8">
                  <div className="flex gap-3 items-center">
                    <h2 className="flex gap-2 items-center">
                      <TiDocumentText
                        style={{ color: "var(--primary)", fontSize: "20px" }}
                      />
                      <div>{record.name}</div>
                    </h2>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8">
                    <Icon
                      fontSize="small"
                      className="text-green-600 cursor-pointer"
                      onClick={() => handleModalShareForm(record.id)}
                    >
                      share
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="text-gray-500 cursor-pointer"
                      onClick={() => handleEditRecord(record)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="color-primary cursor-pointer"
                      onClick={() => handleDeleteRecord(record)}
                    >
                      delete
                    </Icon>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="add">
              Ops! Nenhum formulário encontrado. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}
      <Drawer
        title={`Editar formulário`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <RecordForm
          record={selectedRecord}
          fetchRecords={() =>
            queryClient.invalidateQueries(["records", currentPage])
          }
          onClose={setIsDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>

      <Modal
        dismissible
        show={openModalShareForm}
        onClose={() => setOpenModalShareForm(false)}
      >
        <Modal.Header>Compartilhar formulário</Modal.Header>
        <Modal.Body className="pb-32">
          <div>
            <label className="mb-2 block">Selecione um cliente:</label>
            <AutoCompleteInput
              noOptionsMessage={"Nenhum cliente encontrado"}
              options={customers}
              onChange={(option) => setSelectedCustomer(option.value)}
              isLoading={isLoadingCustomers}
              value={selectedCustomer}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              className="w-full"
            />
          </div>

          {selectedCustomer && (
            <div className="mt-8">
              <a
                href={`https://wa.me/55${customers
                  .find((cust) => cust.value === selectedCustomer)
                  ?.phoneNumber?.replace(
                    /[\(\)\s,-]/g,
                    ""
                  )}?text=https://app.relinq.com.br/site/${
                  user?.companyId
                }/${selectedRecordId}/${selectedCustomer}`}
                className="bg-green-600 mx-auto p-2 text-white rounded-md block text-center"
              >
                Compartilhar no whatsapp
              </a>
              <div className="text-center my-2">ou</div>
              <div
                className="p-2 border border-gray-400 rounded-md relative cursor-pointer overflow-hidden"
                onClick={() =>
                  handleCopyClipboard(
                    `https://app.relinq.com.br/site/${user?.companyId}/${selectedRecordId}/${selectedCustomer}`
                  )
                }
              >
                <div>{`https://app.relinq.com.br/site/${user?.companyId}/${selectedRecordId}/${selectedCustomer}`}</div>
                <div className="absolute right-0 top-3 z-5 bg-white px-3">
                  <MdContentCopy />
                </div>
              </div>
              {isLinkCopied && (
                <Badge className="mt-2" color="green">
                  Link copiado!
                </Badge>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button
            className="primary"
            onClick={() => setOpenModalShareForm(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalDelete
        handleDelete={deleteRecord}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir essa ficha?"
        isLoading={isLoadingDelete}
      />
      <ModalConfirmAdditionalPlan planId={2} plans={["PREMIUM", "DIAMOND", "PARCERIA"]} />
    </>
  );
};

export default RecordsTable;
