import React, { createContext } from "react";
import { useQuery } from "react-query";
import { COMPANIES, PROFESSIONALS } from "../config/apiConfig";
import { getUserId } from "../utils/permissions";
import axios from "../config/axiosInstance";

const ApplicationContext = createContext();

const PermissionProvider = ({ children }) => {
  const { data: user } = useQuery(["user"], async () => {
    try {
      const userId = getUserId();
      if (!userId) throw new Error("");

      const { data } = await axios.get(
        `${PROFESSIONALS.GET_PROFESSIONAL}/${userId}`
      );

      return data.data;
    } catch (error) {
      return "USER";
    }
  });

  const {
    data: additionalPlansCompany,
    isLoading: isLoadingAddtionalPlansCompany,
  } = useQuery(["additionalPlansCompany"], async () => {
    try {
      const { data } = await axios.get(`${COMPANIES.GET_ADDITIONAL_PLANS}`);

      return data.data;
    } catch (error) {
      return "ADDITIONAL-PLANS";
    }
  });

  return (
    <ApplicationContext.Provider
      value={{
        user,
        userPermission: user?.permissionId,
        additionalPlansCompany,
        isLoadingAddtionalPlansCompany,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplication = () => React.useContext(ApplicationContext);

export { PermissionProvider, useApplication };
