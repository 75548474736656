import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Avatar, Badge, Button, Dropdown } from "flowbite-react";
import { permissionChecker, removeToken } from "../utils/permissions";
import { useApplication } from "../contexts/ApplicationContext";
import { calculateExpirationPeriodTest } from "../utils/utils";
import { IoMenuOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import Notification from "./Notification";

const Sidebar = () => {
  const { user, userPermission } = useApplication();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [titlePage, setTitlePage] = useState("Dashboard");
  const sidebarRef = useRef(null);
  const menuBtnRef = useRef(null);
  const daysLeft = calculateExpirationPeriodTest(
    user?.Company?.planExpirationDate
  );

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const menuLinks = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: "equalizer",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Faturamento",
      link: "/invoice",
      icon: "attach_money",
      permission: ["ADMIN"],
      isNew: false,
    },
    {
      name: "Agenda",
      link: "/calendar",
      icon: "today",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Comandas",
      link: "/commands",
      icon: "receipt_long",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Clientes",
      link: "/customers",
      icon: "group",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Profissionais",
      link: "/professionals",
      icon: "hail",
      permission: ["USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Produtos",
      link: "/products",
      icon: "shopping_bag",
      permission: ["USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Serviços",
      link: "/services",
      icon: "design_services",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Categorias",
      link: "/categories",
      icon: "category",
      permission: ["USER", "USER_CALENDARS"],
      isNew: false,
    },
    {
      name: "Site Online",
      link: "/online-site",
      icon: "public",
      permission: ["USERS_CALENDARS"],
      isNew: false,
    },
    {
      name: "Pacotes",
      link: "/packages",
      icon: "grid_view",
      permission: ["ADMIN"],
      isNew: true,
    },
    {
      name: "Formulários",
      link: "/records",
      icon: "assignment",
      permission: ["USER"],
      isNew: false,
    },
    // {
    //   name: "Avaliações",
    //   link: "/reviews",
    //   icon: "star_half",
    //   permission: ["USER"],
    //   isNew: false,
    // },
    {
      name: "Planos Adicionais",
      link: "/additional-plans",
      icon: "add_business",
      permission: ["ADMIN"],
      isNew: false,
    },
    {
      name: "Tutoriais",
      link: "/tutorials",
      icon: "play_circle",
      permission: ["USER"],
      isNew: false,
    },
  ];

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTitlePage(
      menuLinks.find((link) => link.link === location.pathname)?.name ||
        "Dashboard"
    );

    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !menuBtnRef.current.contains(event.target) &&
        isOpen
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname, isOpen]);

  const renderMessageExpirationDate = () => {
    if (!user?.Company?.periodTest) return null;

    let message = "";
    let show = false;

    if (user?.Company?.periodTest && daysLeft > 0) {
      message = ` Você possui o limite de ${daysLeft} dias de período de teste.`;
      show = true;
    } else {
      message = `Seu plano expirou! Para poder retomá-lo, entre em contato conosco através do link abaixo.`;
      show = true;
    }

    return (
      <div
        className={`rounded-md bg-white 0 p-2 text-sm text-center mb-4 ${
          !show ? "hidden" : ""
        }`}
      >
        {message}
        <a
          target="_blank"
          href="https://wa.me/+5515996730404"
          className="secondary mx-auto mt-2 bg-secondary text-white rounded-md py-2 px-4 block"
          rel="noreferrer"
        >
          Contratar agora mesmo
        </a>
      </div>
    );
  };

  return (
    <>
      <aside
        id="logo-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform bg-white border-r sm:translate-x-0 ${
          isOpen ? "" : "-translate-x-full"
        } dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 bg-white dark:bg-gray-800 pt-3">
          <img
            src="/assets/logo.png"
            alt="Logo da Relinq"
            style={{ height: "35px" }}
            className="mx-auto mb-4"
          />
          {renderMessageExpirationDate()}
          <div className="overflow-y-auto pr-2" style={{ maxHeight: "75vh" }}>
            {daysLeft > 0 ? (
              <ul className="space-y-2 font-medium">
                {menuLinks.map((menuLink, index) => (
                  <React.Fragment key={index}>
                    {permissionChecker(menuLink.permission, userPermission) && (
                      <li className="relative">
                        <Link
                          to={menuLink.link}
                          className={`flex items-center p-2 text-gray-900 rounded-lg group ${
                            location.pathname === menuLink.link &&
                            "active shadow-lg"
                          }`}
                          onClick={handleToggleSidebar}
                        >
                          <Icon>{menuLink.icon}</Icon>
                          <span className="ms-3">{menuLink.name}</span>
                        </Link>
                        {menuLink.isNew && (
                          <Badge
                            className="absolute right-2 top-2.5"
                            color="green"
                          >
                            Novo
                          </Badge>
                        )}
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            ) : (
              <ul className="animate-pulse flex flex-col gap-5">
                {Array.from({ length: 14 }).map((_, index) => (
                  <div
                    key={index}
                    className="rounded-xl bg-secondary h-7 w-full"
                  ></div>
                ))}
              </ul>
            )}
          </div>
        </div>
      </aside>
      <div className="sm:ml-64 mt-0">
        <div className="container mx-auto">
          <div className="mainbar px-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <img
                  src="/assets/logo_mini.png"
                  style={{ height: "23px", marginTop: "-3px" }}
                />
                <h2 className="font-bold">{titlePage}</h2>
                <div className="w-fit notifications-popup">
                  <Notification />
                </div>
              </div>
              <div className="flex items-center gap-3 avatar">
                <h2 className="hidden md:block">Bem vindo, {user?.name}</h2>
                <Dropdown
                  label={
                    <div className="flex gap-1 items-center">
                      <RiArrowDropDownLine fontSize={25} />
                      <div className="relative">
                        <Avatar
                          rounded
                          size="sm"
                          img={user?.imageUrl}
                          className="outline outline-2 outline-white rounded-full focus:outline-none focus-visible:outline-none"
                        />
                        <IoMenuOutline className="bg-primary absolute right-0 top-5 rounded-full outline outline-2 outline-white" />
                      </div>
                    </div>
                  }
                  arrowIcon={false}
                  inline
                >
                  <Dropdown.Header>
                    <span className="block truncate text-sm font-medium">
                      {user?.email}
                    </span>
                  </Dropdown.Header>
                  <Dropdown.Item className="py-0 px-0">
                    <Link to="/profile" className="w-full py-2 text-left px-4">
                      Meu perfil
                    </Link>
                  </Dropdown.Item>
                  {permissionChecker("ADMIN", userPermission) && (
                    <Dropdown.Item className="py-0 px-0">
                      <Link
                        to="/company"
                        className="w-full py-2 text-left px-4"
                      >
                        Minha empresa
                      </Link>
                    </Dropdown.Item>
                  )}

                  <Dropdown.Divider />
                  <Dropdown.Item className="py-0 px-0">
                    <a
                      target="_blank"
                      href="https://wa.me/+5515996730404"
                      className="w-full py-2 text-left px-4"
                      rel="noreferrer"
                    >
                      Suporte e Assistência
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item className="py-0 px-0">
                    <a
                      target="_blank"
                      href="https://wa.me/+5515996730404"
                      className="w-full py-2 text-left px-4"
                      rel="noreferrer"
                    >
                      Entre em contato
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={removeToken}>Sair</Dropdown.Item>
                </Dropdown>
                <button
                  ref={menuBtnRef}
                  type="button"
                  onClick={handleToggleSidebar}
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
                >
                  <span className="sr-only">Abrir sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="#fff"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
