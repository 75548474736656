import { Badge } from "flowbite-react";

const CalendarEvent = ({ event }) => {
  return (
    <div>
      {!event?.isBlock ? (
        <>
          <div className="mt-1 font-bold flex flex-row gap-2 items-left md:items-center text-sm flex-wrap">
            <span className="text-xs">{event.title}</span>
            {event.isOnline && (
              <Badge
                color="cyan"
                className="mt-0 w-fit"
                style={{ fontSize: "10px", padding: "0px 2px" }}
              >
                Agendado Online
              </Badge>
            )}
            <Badge
              color={event.statusId === 1 ? "success" : "warning"}
              className="mt-0 w-fit"
              style={{ fontSize: "10px", padding: "0px 2px" }}
            >
              {event.description}
            </Badge>
            {!event.command ? (
              <Badge
                color="dark"
                style={{ fontSize: "10px", padding: "0px 2px" }}
              >
                Sem Comanda
              </Badge>
            ) : (
              <Badge
                color={event.command.statusId === 0 ? "warning" : "success"}
                style={{ fontSize: "10px", padding: "0px 2px" }}
              >
                {event.command.statusId === 0
                  ? "Comanda pendente"
                  : "Comanda faturada"}
              </Badge>
            )}
          </div>
          <span className="block mt-1 text-xs">{event.service}</span>
          {event.isVip && (
            <svg width="30px" height="30px" viewBox="0 0 32 32" fill="none">
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M27 7H5a2 2 0 00-2 2v14a2 2 0 002 2h22a2 2 0 002-2V9a2 2 0 00-2-2z"
              />
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 13l3 6.5 3-6.5M16.5 13v6.5M20.5 17.275h1.48c.651 0 1.277-.275 1.721-.75a2.338 2.338 0 00.215-2.932 1.394 1.394 0 00-1.14-.593H20.5v4.275zm0 0V19.5"
              />
            </svg>
          )}
          {event.observation && (
            <small className="italic mt-1 block text-xs">
              Obs: {event.observation}
            </small>
          )}
        </>
      ) : (
        <div className="text-xs font-semibold">
          <div className="mb-1">Horário bloqueado</div>
          <div>Profissional: {event?.professionalName}</div>
          {event.observation && (
            <small className="italic mt-1 block text-xs">
              Obs: {event.observation}
            </small>
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarEvent;
