import Input from "../inputs/Input";
import { COMMANDS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { Button, Modal } from "flowbite-react";
import { ImCreditCard } from "react-icons/im";
import { MdPix } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { CiMoneyCheck1 } from "react-icons/ci";
import { FaArrowsLeftRight } from "react-icons/fa6";
import { formatCurrencyBRL } from "../../utils/utils";

const CommandPaymentForm = ({
  command,
  setCommand,
  paymentTaxes,
  openModal,
  setOpenModal,
  setOpenParentModal,
  fetchCommands,
  register,
  setValue,
  watch,
  totalPrice,
  type = "CREATE",
}) => {
  const OBJ_PAYMENT = { paymentId: "", price: 0, taxPercentage: 0 };
  const [activePayment, setActivePayment] = useState(null);
  const [numberPayments, setNumberPayments] = useState([OBJ_PAYMENT]);

  const paymentMethods = [
    {
      id: "CARTAO_CREDITO",
      icon: <ImCreditCard style={{ fontSize: "20px" }} />,
      name: "Cartão de crédito",
    },
    {
      id: "CARTAO_DEBITO",
      icon: <ImCreditCard style={{ fontSize: "20px" }} />,
      name: "Cartão de débito",
    },
    {
      id: "DINHEIRO",
      icon: <BsCashCoin style={{ fontSize: "20px" }} />,
      name: "Dinheiro",
    },
    {
      id: "PIX",
      icon: <MdPix style={{ fontSize: "20px" }} />,
      name: "PIX",
    },
    {
      id: "CHEQUE",
      icon: <CiMoneyCheck1 style={{ fontSize: "20px" }} />,
      name: "Cheque",
    },
  ];

  const handlePaymentClick = (index, methodId) => {
    setActivePayment(index);

    if (numberPayments.length === 1) {
      setValue(`payments[${index}].price`, totalPrice);
    }

    setValue(`payments[${index}].paymentId`, methodId);
    setValue(`payments[${index}].taxPercentage`, 0);
  };

  const paymentsList = watch("payments");

  const calculateGivenAmount = () => {
    return paymentsList
      ? paymentsList.reduce((accumulator, currentValue) => {
          return accumulator + +currentValue.price;
        }, 0)
      : 0;
  };

  const calculateRemainingAmout = () => {
    const totalValue = totalPrice - calculateGivenAmount();
    const className = totalValue > 0 ? "text-red-500" : "text-green-500";
    const text = totalValue > 0 ? "Faltam" : "Adicional";

    return (
      <>
        {totalValue !== 0 && (
          <div className={`${className}`}>
            {text}: {formatCurrencyBRL.format(totalValue)}
          </div>
        )}
      </>
    );
  };

  const submitPayment = async () => {
    const data = {};
    data.commandId = command.id;
    data.payments = watch("payments");
    data.statusId = 1;
    data.totalPrice = calculateGivenAmount();
    data.services = watch("services");
    data.products = watch("products");
    data.discount = watch("discount");

    if (
      data?.services &&
      data.services.length === 1 &&
      !data.services[0].serviceId
    ) {
      delete data.services;
    }

    if (
      data?.products &&
      data.products.length === 1 &&
      !data.products[0].productId
    ) {
      delete data.products;
    }

    data.payments = data.payments.map((payment) => ({
      ...payment,
      taxPercentage:
        paymentTaxes.find((tax) => payment.paymentId === tax.id)
          ?.taxPercentage || 0,
    }));

    await axios.put(`${COMMANDS.GET_COMMAND}/${command.id}`, data);
    setValue("statusId", 1);
    setValue(
      "Payments",
      watch("payments").map((payment) => ({
        CommandPayment: {
          taxPercentage: payment.taxPercentage,
          price: payment.price,
        },
      }))
    );

    setNumberPayments([OBJ_PAYMENT]);
    fetchCommands();
    setOpenModal(false);
    setOpenParentModal(false);
  };

  const handleClose = () => {
    setNumberPayments([OBJ_PAYMENT]);
    setValue("payments", []);
    setOpenModal(false);
  };

  return (
    <Modal show={openModal} onClose={handleClose}>
      <Modal.Header>Pagamento</Modal.Header>
      <Modal.Body>
        <small
          className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
          style={{ fontSize: 10 }}
        >
          <span>Deslize para ver todos os métodos de pagamento</span>{" "}
          <FaArrowsLeftRight />
        </small>
        {numberPayments.map((_, indexNumber) => (
          <div key={indexNumber}>
            <div className="overflow-x-scroll py-2">
              <div
                className="flex gap-3 flex-row"
                style={{ minWidth: "550px" }}
              >
                {paymentMethods.map((method, index) => (
                  <button
                    key={index}
                    className={`w-full flex flex-col items-center shadow-md justify-center gap-1 text-center rounded-md bg-gray-100 p-2  
                    ${
                      watch(`payments[${indexNumber}].paymentId`) ===
                        method.id && "bg-primary text-white"
                    }`}
                    onClick={() => handlePaymentClick(indexNumber, method.id)}
                  >
                    {method.icon}
                    <span className="text-xs">{method.name}</span>
                  </button>
                ))}
              </div>
            </div>
            <div className="flex gap-2">
              <Input
                className="w-full mt-2"
                type="number"
                register={register}
                label="Valor"
                name={`payments[${indexNumber}].price`}
                value={watch(`payments[${indexNumber}].price`)}
                step="0.01"
              />
              <Input
                className="w-full mt-2"
                type="number"
                register={register}
                label="Taxa %"
                step="0.01"
                name={`payments[${indexNumber}].taxPercentage`}
                value={
                  paymentTaxes?.find(
                    (tax) =>
                      tax.id === watch(`payments[${indexNumber}].paymentId`)
                  )?.taxPercentage
                }
                disabled
              />
            </div>
          </div>
        ))}

        <div className="flex gap-2 mt-4 justify-end mb-5">
          {numberPayments.length > 1 && (
            <Button
              className="bg-gray-500"
              onClick={() => {
                setNumberPayments((prev) =>
                  Array.from({
                    length: prev.length > 1 ? prev.length - 1 : prev.length,
                  })
                );
                setValue("payments", watch("payments").slice(0, -1));
              }}
            >
              Remover pagamento
            </Button>
          )}

          <Button
            className="primary"
            onClick={() =>
              setNumberPayments((prev) =>
                Array.from({
                  length: prev.length < 4 ? prev.length + 1 : prev.length,
                })
              )
            }
          >
            Adicionar pagamento
          </Button>
        </div>
        <div>
          {calculateRemainingAmout()}
          <div className="font-bold">
            Total da comanda: {formatCurrencyBRL.format(totalPrice)}
          </div>
          <div className="text-gray-700">
            Total Fornecido: {formatCurrencyBRL.format(calculateGivenAmount())}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="primary"
          onClick={submitPayment}
          disabled={totalPrice - calculateGivenAmount() > 0}
        >
          Faturar
        </Button>
        <Button color="gray" onClick={handleClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommandPaymentForm;
