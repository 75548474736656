//REMOVER ESSE CODIGO POSTERIORMENTE
import { BASE_URL, APPOINTMENTS } from "./apiConfig";

export const formatWhatsappMessage = (customerName = "", appointmentId) => {
  let message = `Olá ${
    customerName || ""
  }! Para confirmar seu agendamento, clique neste link: ${BASE_URL}${
    APPOINTMENTS.GET_APPOINTMENTS
  }/confirm?id=${appointmentId}`;

  return encodeURIComponent(message);
};

export const formatBirthdayMessage = (customerName) => {
  let message = `Olá ${customerName}! Em nome de toda a equipe, gostaríamos de aproveitar esta ocasião para lhe desejar um dia repleto de alegria, amor e momentos felizes ao lado daqueles que você ama. Que este novo ano traga consigo muitas conquistas, saúde, prosperidade e realizações em todos os aspectos da sua vida.`;

  return encodeURIComponent(message);
};
