import { useQuery } from "react-query";
import { REVIEWS } from "../config/apiConfig";
import { useApplication } from "../contexts/ApplicationContext";
import axios from "../config/axiosInstance";
import { Avatar, Badge, Card, List, Rating } from "flowbite-react";
import { TiStar } from "react-icons/ti";

const ReviewsPage = () => {
  const { user } = useApplication();
  const {
    isLoading,
    error,
    data: reviews,
  } = useQuery(["reviews"], async () => {
    const { data: item } = await axios.get(
      `${REVIEWS.GET_BY_PROFESSIONALS}/${user?.companyId}`
    );

    return item.data;
  });

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <Card className="h-full">
            <div>
              <div className="flex flex-col items-center mb-5">
                <div className="font-semibold">Total de avaliações</div>
                <div className="text-3xl font-semibold flex gap-1 items-center mt-2">
                  <Badge color="success" size="lg">
                    346
                  </Badge>
                </div>
              </div>
              <hr />
              <div className="flex flex-col items-center mt-5">
                <div className="font-semibold">Média das avaliações</div>
                <div className="text-3xl font-semibold flex gap-1 items-center mt-2">
                  <div>4.8</div>
                  <TiStar color="#FFCD1F" />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-span-2">
          <Card className="h-full card-dashboard">
            <div className="font-semibold">Últimas avaliações</div>
            <List
              unstyled
              className="w-full divide-y divide-gray-200 dark:divide-gray-700"
            >
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
            </List>
          </Card>
        </div>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4 mt-4">
        <div>
          <Card className="h-full card-dashboard">
            <div className="font-semibold">Ranking de avaliações</div>
            <List
              unstyled
              className="w-full divide-y divide-gray-200 dark:divide-gray-700"
            >
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    4.5
                    <Rating>
                      <Rating.Star />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    4.5
                    <Rating>
                      <Rating.Star />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    4.5
                    <Rating>
                      <Rating.Star />
                    </Rating>
                  </div>
                </div>
              </List.Item>
            </List>
          </Card>
        </div>
        <div className="col-span-2">
          <Card className="h-full card-dashboard">
            <div className="font-semibold">Últimos atendimentos</div>
            <List
              unstyled
              className="w-full divide-y divide-gray-200 dark:divide-gray-700"
            >
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
              <List.Item className="pb-3 sm:pb-4 pt-3">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="bg-gray-200 overflow-hidden rounded-full h-10 w-10"></div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                      Neil Sims
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Maiores facilis nihil pariatur deserunt dolores
                      perspiciatis officia harum dolor voluptas? Necessitatibus
                      voluptatibus eum voluptatum exercitationem ut expedita
                      mollitia rem ab praesentium!
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <Rating>
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star />
                      <Rating.Star filled={false} />
                    </Rating>
                  </div>
                </div>
              </List.Item>
            </List>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ReviewsPage;
