import { PRODUCTS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useRef, useState } from "react";
import { Alert, Badge, List, Tabs } from "flowbite-react";
import { useQuery } from "react-query";
import moment from "moment";
import DrawerLoading from "../DrawerLoading";

const ProductHistoryForm = ({ product, isDrawerOpen, onClose }) => {
  const [isEnabledHistory, setIsEnabledHistory] = useState(false);
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const {
    isLoading: isLoadingProductInputs,
    error: errorProductInputs,
    data: productInputs,
  } = useQuery(
    ["productInputs", product?.id],
    async () => {
      const { data: items } = await axios.get(
        `${PRODUCTS.STOCK}/${product?.id}`
      );
      return items.data;
    },
    { enabled: isEnabledHistory }
  );
  const {
    isLoading: isLoadingProductOutputs,
    error: errorProductOutputs,
    data: productOutputs,
  } = useQuery(
    ["productOutputs", product?.id],
    async () => {
      const { data: items } = await axios.get(
        `${PRODUCTS.GET_OUTPUTS}/${product?.id}`
      );
      return items.data;
    },
    { enabled: isEnabledHistory }
  );

  useEffect(() => {
    setIsEnabledHistory(true);
  }, [product, isDrawerOpen]);

  return (
    <>
      <Tabs
        aria-label="Tabs with underline"
        variant="underline"
        ref={tabsRef}
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        <Tabs.Item active title="Entradas">
          {!isLoadingProductInputs ? (
            <>
              {productInputs?.length > 0 ? (
                <List
                  unstyled
                  className="w-full divide-y divide-gray-200 dark:divide-gray-700"
                >
                  {productInputs?.map((input, index) => (
                    <List.Item key={index} className="py-2 sm:py-3">
                      <div className="text-sm md:text-base">
                        Quantidade: {input.quantity}
                      </div>
                      <div className="text-sm md:text-base">
                        Data:{" "}
                        {moment(input.createdAt).format("DD/MM/yyyy | HH:mm")}
                      </div>
                      <div className="flex items-center gap-2 text-sm md:text-base">
                        <div> Profissional:</div>{" "}
                        <Badge className="w-fit">
                          {input.Professional.name}
                        </Badge>
                      </div>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div className="h-48 flex justify-center items-center">
                  Nenhuma entrada registrada para este produto.
                </div>
              )}
            </>
          ) : (
            <DrawerLoading />
          )}
        </Tabs.Item>
        <Tabs.Item title="Saídas">
          {!isLoadingProductOutputs ? (
            <>
              {productOutputs?.length > 0 ? (
                <List
                  unstyled
                  className="w-full divide-y divide-gray-200 dark:divide-gray-700"
                >
                  {productOutputs?.map((output, index) => (
                    <List.Item key={index} className="py-2 sm:py-3">
                      <div className="text-sm md:text-base">
                        Quantidade: {output.quantity}
                      </div>
                      <div className="text-sm md:text-base">
                        Data:{" "}
                        {moment(output.updatedAt).format("DD/MM/yyyy | HH:mm")}
                      </div>
                      <div className="flex items-center gap-2 text-sm md:text-base">
                        <div> Profissional:</div>{" "}
                        <Badge className="w-fit">
                          {output.professionalName}
                        </Badge>
                      </div>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div className="h-48 flex justify-center items-center">
                  Nenhuma saída registrada para este produto.
                </div>
              )}
            </>
          ) : (
            <DrawerLoading />
          )}
        </Tabs.Item>
      </Tabs>
    </>
  );
};

export default ProductHistoryForm;
