import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { PROFESSIONALS } from "../../config/apiConfig";
import { Button, Pagination, Card, Badge } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import ProfessionalForm from "../forms/ProfessionalForm";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import { IoMdAdd } from "react-icons/io";
import { useApplication } from "../../contexts/ApplicationContext";
import { getFirstLettersName } from "../../utils/utils";

const ProfessionalsTable = () => {
  const { user } = useApplication();
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: professionals,
  } = useQuery(["professionals", currentPage], async () => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONALS}?page=${currentPage}`
    );
    setTotalPages(items.data.totalPages || 1);
    return items.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProfessional((prevProfessional) => ({
      ...prevProfessional,
      [name]: value,
    }));
  };

  const handleAddProfessional = () => {
    setSelectedProfessional(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditProfessional = (professional) => {
    setTypeDrawer("EDIT");
    setSelectedProfessional(professional);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteProfessional = (professional) => {
    setSelectedProfessional(professional);
    setOpenModalDelete(true);
  };

  const deleteProfessional = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(
        `${PROFESSIONALS.GET_PROFESSIONAL}/${selectedProfessional.id}`
      );

      queryClient.invalidateQueries(["professionals", currentPage]);
      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      {user?.Company.numberOfUsers > professionals?.length && (
        <div className="flex justify-end mb-3 mt-2">
          <Button
            className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
            variant="contained"
            disabled={isLoading ? true : false}
            onClick={handleAddProfessional}
          >
            <IoMdAdd className="block md:hidden text-2xl" />

            <span className="hidden md:block">Adicionar profissional</span>
          </Button>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
        {!isLoading
          ? professionals.map((professional, index) => (
              <Card key={index} className="relative">
                <div className="flex gap-3 items-center">
                  {!professional?.imageUrl ? (
                    <div
                      className="flex items-center justify-center"
                      style={{
                        height: "40px",
                        width: "40px",
                        fontSize: "18px",
                        color: "black",
                        borderRadius: "50%",
                        minWidth: "40px",
                        background: `${professional.colorSchedule}`,
                      }}
                    >
                      {getFirstLettersName(professional.name)}
                    </div>
                  ) : (
                    <div>
                      <img
                        src={professional?.imageUrl}
                        className="rounded-full"
                        style={{
                          objectFit: "cover",
                          width: "40px",
                          minWidth: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  )}
                  <div className="flex gap-2 items-center">
                    <h2>{professional?.name}</h2>
                    {professional?.permissionId === "ADMIN" && (
                      <Badge color="pink" style={{ fontSize: "9px" }}>
                        {professional.permissionId}
                      </Badge>
                    )}
                    {professional?.isActive ? (
                      <Badge color="success" style={{ fontSize: "10px" }}>
                        Ativo
                      </Badge>
                    ) : (
                      <Badge color="gray" style={{ fontSize: "10px" }}>
                        Inativo
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 text-gray-500 text-xs">
                  <Icon fontSize="small">email</Icon>
                  <span className="text-sm truncate">
                    E-mail: {professional.email}
                  </span>
                </div>
                <div className="flex gap-1 text-gray-500 mr-20">
                  <Icon fontSize="small">phone</Icon>
                  <span className="text-sm truncate mr-12">
                    Celular: {professional.phoneNumber}
                  </span>
                </div>
                <div className="flex absolute bottom-5 right-3 gap-8">
                  <Icon
                    fontSize="small"
                    className="text-gray-500 cursor-pointer"
                    onClick={() => handleEditProfessional(professional)}
                  >
                    edit
                  </Icon>
                  <Icon
                    fontSize="small"
                    className="color-primary cursor-pointer"
                    onClick={() => handleDeleteProfessional(professional)}
                  >
                    delete
                  </Icon>
                </div>
              </Card>
            ))
          : Array.from({ length: 12 }).map((_, index) => (
              <CardLoading key={index} />
            ))}
      </div>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${
          typeDrawer === "CREATE"
            ? "Cadastrar novo profissional"
            : "Editar profissional"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <ProfessionalForm
          professional={selectedProfessional}
          handleInputChange={handleInputChange}
          fetchProfessionals={() =>
            queryClient.invalidateQueries(["professionals", currentPage])
          }
          onClose={setIsDrawerOpen}
          isOpen={isDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>

      <ModalDelete
        handleDelete={deleteProfessional}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir esse profissional?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default ProfessionalsTable;
