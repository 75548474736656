import React from "react";

const Loading = () => {
  return (
    <div className="flex flex-col items-center gap-2 h-48 justify-center">
      <span className="loader-name">Rel&nbsp;nq</span>
      <span className="loader-circle"></span>
    </div>
  );
};

export default Loading;
