import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Tabs } from "flowbite-react";
import useCEP from "../../hooks/useCEP";
import { useForm } from "react-hook-form";
import { AVATAR, PROFESSIONALS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import CPFInput from "../inputs/CPFInput";
import PhoneInput from "../inputs/PhoneInput";
import { resetValuesForm } from "../../utils/utils";
import SelectInput from "../inputs/SelectInput";
import { HiInformationCircle } from "react-icons/hi";
import ImageInput from "../inputs/ImageInput";
import { useApplication } from "../../contexts/ApplicationContext";

const ProfessionalForm = ({
  professional,
  fetchProfessionals,
  onClose,
  isOpen,
  type = "CREATE",
}) => {
  const { userPermission } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [avatarImg, setAvatarImg] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (type === "EDIT" && professional) {
      Object.keys(professional).forEach((key) => {
        setValue(key, professional[key]);
      });
      setPreviewUrl(professional?.imageUrl);
      setAvatarImg(professional?.avatar);
      setKey((prev) => prev + 1);
    } else if (type === "CREATE") {
      resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
      setPreviewUrl("");
      setAvatarImg("");
    }
    setValue("password", "");
    setError(null);
  }, [professional, isOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      if (previewUrl.includes("data:image/")) {
        const formData = new FormData();
        formData.append("image", avatarImg);
        const { data: avatar } = await axios.post(
          `${AVATAR.UPLOAD}?avatar=${professional?.avatar || ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        data.avatar = avatar.imageName;
      }

      if (professional?.email !== data.email) {
        const responseEmail = await axios.get(
          `${PROFESSIONALS.GET_PROFESSIONAL}/email/${data.email}`
        );

        if (responseEmail.status === "error") {
          throw new Error("E-mail já cadastrado");
        }
      }

      if (type === "CREATE") {
        await axios.post(PROFESSIONALS.GET_PROFESSIONAL, data);
      } else {
        if (data.password === "") delete data.password;
        await axios.put(
          `${PROFESSIONALS.GET_PROFESSIONAL}/${professional.id}`,
          data
        );
      }

      onClose(false);

      fetchProfessionals();

      if (type === "CREATE")
        resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  return (
    <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <ImageInput
          image={avatarImg}
          setImage={setAvatarImg}
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
        />
      </div>
      <Tabs aria-label="Tabs with underline" variant="underline">
        <Tabs.Item active title="Informações">
          <div className="mb-6">
            <Input
              type="text"
              label="Nome *"
              name="name"
              required={true}
              register={register}
              error={errors?.name && "Este campo é obrigatório"}
            />
          </div>

          <div className="mb-4">
            <Input
              type="email"
              label="E-mail *"
              name="email"
              required={true}
              register={register}
              error={errors?.email && "Este campo é obrigatório"}
            />
            {errors.email && <span>{errors.email.message}</span>}
            <small className="italic">
              Esse será o email utilizado para o login do profissional
            </small>
          </div>

          {["ADMIN", "POWER_USER"].includes(userPermission) && (
            <div className="mb-6">
              <Input
                type="password"
                label={`Senha ${type === "CREATE" ? "*" : ""}`}
                name="password"
                required={type === "CREATE"}
                register={register}
              />
            </div>
          )}

          <div className="mb-6">
            <PhoneInput
              type="text"
              label="Telefone *"
              name="phoneNumber"
              required={true}
              register={register}
              error={errors?.phoneNumber && "Este campo é obrigatório"}
            />
          </div>

          <div className="mb-6">
            <CPFInput
              type="text"
              label="Cpf"
              name="cpf"
              required={false}
              register={register}
              value={watch("cpf") || ""}
            />
          </div>

          <div className="mb-6 flex gap-2">
            <Input
              type="text"
              label="CEP"
              name="addressPostalCode"
              required={false}
              register={register}
            />
            <Button
              onClick={onClickSearchCEP}
              className="flex align-center whitespace-nowrap primary"
              style={{ width: "220px", paddingTop: "4px" }}
              isProcessing={loadingCEP}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
            >
              Buscar CEP
            </Button>
          </div>
          <div className="mb-6">
            <Input
              type="text"
              label="Endereço"
              name="address"
              required={false}
              register={register}
            />
          </div>
          <div className="mb-6">
            <div className="flex gap-4">
              <Input
                type="text"
                label="Número"
                name="addressNumber"
                required={false}
                register={register}
              />
              <Input
                type="text"
                label="Bairro"
                name="addressNeighborhood"
                required={false}
                register={register}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="flex gap-4">
              <Input
                type="text"
                label="Cidade"
                name="addressCity"
                required={false}
                register={register}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="flex flex-col md:flex-row gap-4">
              <Input
                type="date"
                label="Data de nascimento"
                name="birthday"
                required={false}
                register={register}
              />
              <div className="w-full relative">
                <select
                  id="gender"
                  name="gender"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  {...register("gender")}
                >
                  <option value="">Selecione o gênero</option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </select>
                <label
                  htmlFor="gender"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Sexo
                </label>
              </div>
            </div>
          </div>
        </Tabs.Item>
        <Tabs.Item active title="Configurações">
          <div className="mb-6 flex gap-2">
            <SelectInput
              label="Cor da agenda"
              name="colorSchedule"
              required={false}
              register={register}
            >
              <option value="#ff9ea4">Vermelho</option>
              <option value="#9fc69a">Verde</option>
              <option value="#eac198">Marrom</option>
              <option value="#aae9ff">Azul</option>
              <option value="#b7b7b7">Cinza</option>
              <option value="#d8d497">Musgo</option>
              <option value="#ffc9ea">Rosa</option>
              <option value="#ffbf75">Laranja</option>
              <option value="#d7a8ff">Roxo</option>
              <option value="#e8e1a0">Amarelo</option>
            </SelectInput>
            {userPermission === "ADMIN" && (
              <SelectInput
                label="Permissão"
                name="permissionId"
                required={true}
                register={register}
              >
                <option value="ADMIN">Administrador</option>
                <option value="USER">Usuário</option>
                <option value="USER_CALENDARS">Recepcionista</option>
              </SelectInput>
            )}
          </div>

          <div className="mb-6 flex gap-2">
            <Input
              type="number"
              label="Comissão (%)"
              name="percentageCommission"
              required={false}
              register={register}
              step="0.01"
            />
          </div>
          <div className="mb-6">
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                name="isActive"
                checked={watch("isActive")}
                {...register("isActive")}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-700"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Profissional ativo?
              </span>
            </label>
          </div>
        </Tabs.Item>
      </Tabs>

      <Button
        type="submit"
        className="w-full mb-48 md:mb-4 primary"
        isProcessing={isSubmitting}
        processingSpinner={
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        }
        disabled={isSubmitting}
      >
        {type === "CREATE" ? "Criar" : "Atualizar"} profissional
      </Button>

      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          {error?.config?.method !== "get"
            ? `Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
          profissional`
            : "E-mail já cadastrado"}
        </Alert>
      )}
    </form>
  );
};

export default ProfessionalForm;
