import React, { useState } from "react";
import { IoMdPerson } from "react-icons/io";
import { COMMANDS, PAYMENTS, PROFESSIONALS } from "../../config/apiConfig";
import { Badge, Button, Card, Modal, Pagination, Table } from "flowbite-react";
import axios from "../../config/axiosInstance";
import { BsFillBarChartFill } from "react-icons/bs";
import { MdOutlineAttachMoney, MdPayments } from "react-icons/md";
import { LuFileSpreadsheet } from "react-icons/lu";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { useQuery } from "react-query";
import FilterToolbar from "../inputs/FilterToolbar";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import {
  calculateAverageInvoice,
  calculateAverageTicket,
  calculateCommission,
  formatCurrencyBRL,
} from "../../utils/utils";
import { FiEye } from "react-icons/fi";
import InvoiceViewForm from "../forms/InvoiceViewForm";
import Drawer from "../Drawer";
import { IoAlertCircleOutline } from "react-icons/io5";
import Loading from "../Loading";
import EmptyTable from "./EmptyTable";

moment.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");

const InvoicesTable = () => {
  const [startDatePeriod, setStartDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDatePeriod, setEndDatePeriod] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedFilterProfessional, setSelectedFilterProfessional] =
    useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderCommands, setOrderCommands] = useState("DESC");
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [isVisibleFilterProfessionals, setIsVisibleFilterProfessionals] =
    useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const [isOpenModalPaymentProfessional, setOpenModalPaymentProfessional] =
    useState(false);

  const {
    isLoadingInvoices,
    errorInvoices,
    data: invoices,
  } = useQuery(
    [
      "invoices",
      startDatePeriod,
      endDatePeriod,
      selectedFilterProfessional,
      paymentType,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${COMMANDS.GET_INVOICES}?professionalId=${selectedFilterProfessional}&startDate=${startDatePeriod}&endDate=${endDatePeriod}&paymentType=${paymentType}`
      );

      return items.data;
    }
  );

  const {
    isLoading,
    error,
    data: commands,
  } = useQuery(
    [
      "commands",
      currentPage,
      orderCommands,
      startDatePeriod,
      endDatePeriod,
      selectedFilterProfessional,
      paymentType,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${COMMANDS.GET_COMMANDS}?page=${currentPage}&professionalId=${selectedFilterProfessional}&order=${orderCommands}&statusId=1&startDate=${startDatePeriod}&endDate=${endDatePeriod}&paymentType=${paymentType}`
      );
      setTotalPages(items.data.totalPages || 1);

      return items.data.data;
    }
  );

  const {
    isLoadingCommandsPaymentProfessional,
    errorCommandsPaymentProfessional,
    data: commandsPaymentProfessional,
  } = useQuery(
    [
      "commandsPaymentProfessional",
      orderCommands,
      startDatePeriod,
      endDatePeriod,
      selectedFilterProfessional,
    ],
    async () => {
      const { data: items } = await axios.get(
        `${COMMANDS.GET_COMMANDS}?itemsPerPage=-1&professionalId=${selectedFilterProfessional}&order=${orderCommands}&statusId=1&startDate=${startDatePeriod}&endDate=${endDatePeriod}`
      );
      setTotalPages(items.data.totalPages || 1);

      return items.data.data;
    },
    { enabled: isOpenModalPaymentProfessional }
  );

  const {
    isLoadingPaymentTaxes,
    errorPaymentTaxes,
    data: paymentTaxes,
  } = useQuery(["paymentTaxes"], async () => {
    const { data: items } = await axios.get(`${PAYMENTS.GET_PAYMENTS}`);

    return items.data.Payments.map((payment) => ({
      id: payment.PaymentCompany.PaymentId,
      name: payment.name,
      taxPercentage: payment.PaymentCompany.taxPercentage,
    }));
  });

  const {
    isLoadingProfessionals,
    errorProfessionals,
    data: professionals,
  } = useQuery(["professionals"], async () => {
    const { data: items } = await axios.get(
      `${PROFESSIONALS.GET_PROFESSIONALS}`
    );

    return items.data.data;
  });

  const onClickSearchPeriod = () => {
    setStartDatePeriod(startDate);
    setEndDatePeriod(endDate);
  };

  const handleChangeInvoice = (invoice) => {
    setIsDrawerOpen(true);
    setSelectedInvoice(invoice);
  };

  const getSelectedProfessional = () =>
    professionals?.find(
      (professional) => professional.id == selectedFilterProfessional
    );

  const getProfessionalsCommand = (command) => {
    let professionals = [];

    command.Services.forEach((service) =>
      professionals.push(service.CommandService.ProfessionalId)
    );
    command.Products.forEach((product) =>
      professionals.push(product.CommandProduct.ProfessionalId)
    );

    return [...new Set(professionals)];
  };

  const calculateTotalServices = () => {
    let totalSum = 0;
    const percentageProfessional =
      getSelectedProfessional()?.percentageCommission;

    commandsPaymentProfessional?.forEach((command) => {
      totalSum += command?.Services?.reduce((accumulator, currentValue) => {
        if (
          currentValue.CommandService.ProfessionalId ==
            selectedFilterProfessional &&
          !currentValue.CommandService.isPackage
        ) {
          return (
            accumulator +
            currentValue.CommandService.quantity *
              currentValue.CommandService.priceUnit
          );
        }
        return accumulator;
      }, 0);
    }, totalSum);

    if (percentageProfessional === 0) {
      return 0;
    }

    return totalSum * (percentageProfessional / 100);
  };

  const calculateTotalProducts = () => {
    let totalSum = 0;
    const percentageProfessional =
      getSelectedProfessional()?.percentageCommission;

    commandsPaymentProfessional?.forEach((command) => {
      totalSum += command?.Products?.reduce((accumulator, currentValue) => {
        if (
          currentValue.CommandProduct.ProfessionalId ==
          selectedFilterProfessional
        ) {
          return (
            accumulator +
            currentValue.CommandProduct.quantity *
              currentValue.CommandProduct.priceUnit
          );
        }
        return accumulator;
      }, 0);
    }, totalSum);

    if (percentageProfessional === 0) {
      return 0;
    }

    return totalSum * (percentageProfessional / 100);
  };

  return (
    <>
      <FilterToolbar
        title="Selecionar Período:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "#8E1B3C" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-sm text-white">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDate}
            className="w-full md:w-fit block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <Button onClick={onClickSearchPeriod} className="secondary">
          Buscar
        </Button>
      </FilterToolbar>
      <div className="mt-5 mb-2 text-sm md:text-md italic">
        Informações baseadas no período selecionado{" "}
        <Badge className="w-fit inline" color="pink">
          {moment(startDatePeriod).format("DD/MM/YYYY")}
        </Badge>{" "}
        -{" "}
        <Badge className="w-fit inline" color="pink">
          {moment(endDatePeriod).format("DD/MM/YYYY")}
        </Badge>
      </div>
      <div className="overflow-x-scroll">
        <div className="flex gap-3 pb-5" style={{ minWidth: "1200px" }}>
          <Card
            className="w-full bg-gray-700 relative"
            style={{ minWidth: "250px" }}
          >
            <div className="text-sm md:text-md lg:text-lg text-white">
              Faturamento total:
            </div>
            <div className="text-2xl text-white font-semibold flex gap-2 items-center">
              {!isLoadingInvoices ? (
                `${formatCurrencyBRL.format(invoices?.totalPriceSum || 0)}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <MdOutlineAttachMoney className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
          </Card>
          <Card className="w-full bg-gray-700 relative overflow-hidden">
            <div className="text-sm md:text-md lg:text-lg text-white">
              Média de faturamento diário:
            </div>
            <div className="text-2xl text-white font-semibold">
              {formatCurrencyBRL.format(
                calculateAverageInvoice(
                  invoices?.totalPriceSum || 0,
                  startDatePeriod,
                  endDatePeriod
                )
              )}
            </div>
            <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
          </Card>
          <Card className="w-full bg-gray-700 relative">
            <div className="text-sm md:text-md lg:text-lg text-white">
              Comandas faturadas:
            </div>
            <div className="text-2xl text-white font-semibold">
              {!isLoadingInvoices ? (
                <>
                  <div>
                    {invoices?.totalCount} de{" "}
                    {+invoices?.totalCountPending + +invoices?.totalCount}
                  </div>
                  {+invoices?.totalCountPending > 0 && (
                    <small className="text-xs font-normal flex gap-1 items-center mt-2">
                      <IoAlertCircleOutline />{" "}
                      <span>Existem comandas não faturadas.</span>
                    </small>
                  )}
                </>
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <LuFileSpreadsheet className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
          </Card>
          <Card className="w-full bg-gray-700 relative">
            <div className="text-sm md:text-md lg:text-lg text-white">
              Ticket médio:
            </div>
            <div className="text-2xl text-white font-semibold">
              {!isLoadingInvoices ? (
                `${formatCurrencyBRL.format(
                  calculateAverageTicket(
                    invoices?.totalPriceSum || 0,
                    invoices?.totalCount
                  )
                )}`
              ) : (
                <div role="status" className="max-w-sm animate-pulse inline">
                  <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
              )}
            </div>
            <FaUser className="absolute bottom-0 right-0 text-white text-9xl opacity-10" />
          </Card>
        </div>
      </div>
      <div className="flex justify-between mb-1 mt-2 gap-2">
        <FilterToolbar
          isVisible={isVisibleFilterProfessionals}
          setIsVisible={setIsVisibleFilterProfessionals}
        >
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <IoMdPerson className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedFilterProfessional}
              onChange={({ target }) =>
                setSelectedFilterProfessional(target.value)
              }
            >
              <option selected value={0}>
                Todos profissionais
              </option>
              {professionals?.map((professional, index) => (
                <option key={index} selected value={professional.id}>
                  {professional?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-2 w-full md:w-fit bg-gray-100 rounded-md pl-2 pr-0 py-1">
            <MdPayments className="color-secondary text-xl mt-1" />
            <select
              id="underline_select"
              className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={paymentType}
              onChange={({ target }) => setPaymentType(target.value)}
            >
              <option selected value="">
                Todos métodos de pagamento
              </option>
              {paymentTaxes?.map((payment, index) => (
                <option key={index} value={payment.id}>
                  {payment?.name}
                </option>
              ))}
            </select>
          </div>
        </FilterToolbar>
      </div>

      {selectedFilterProfessional != 0 &&
        !isLoadingProfessionals &&
        commands?.length > 0 && (
          <div className="mt-2 flex justify-end items-center">
            <Button
              className="primary shadow-md"
              onClick={() => setOpenModalPaymentProfessional(true)}
            >
              Calcular pagamento de{" "}
              {getSelectedProfessional()?.name.split(" ")[0]}
            </Button>
          </div>
        )}

      {!isLoading ? (
        <>
          {commands?.length > 0 ? (
            <>
              <small
                className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
                style={{ fontSize: 10 }}
              >
                <span>
                  Deslize para o lado para visualizar todas as informações
                </span>{" "}
                <FaArrowsLeftRight />
              </small>
              <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
                <Table striped>
                  {commands?.length > 0 ? (
                    <Table.Head>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Comanda
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        É pacote?
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Cliente
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Profissionais:
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Pagamentos:
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Valor total:
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Ações:
                      </Table.HeadCell>
                    </Table.Head>
                  ) : (
                    <></>
                  )}

                  <Table.Body className="divide-y">
                    {commands.length > 0 && (
                      <>
                        {commands.map((command, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              #{command.commandNumber}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              {command.typeCommand === "C" ? (
                                "Não"
                              ) : (
                                <Badge className="w-fit mx-auto">Sim</Badge>
                              )}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              {moment(command.date).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              {command.Customer?.name}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              <div className="flex gap-1 justify-center items-center flex-wrap">
                                {getProfessionalsCommand(command).map(
                                  (professional, index) => (
                                    <Badge
                                      className="w-fit"
                                      color="indigo"
                                      key={index}
                                    >
                                      {
                                        professionals?.find(
                                          (pr) => pr.id === professional
                                        )?.name
                                      }
                                    </Badge>
                                  )
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              <div className="flex gap-1 justify-center items-center flex-wrap">
                                {command.Payments.map((payment, index) => (
                                  <Badge
                                    key={index}
                                    className="w-fit whitespace-nowrap"
                                    color="failure"
                                  >
                                    {payment.name}
                                  </Badge>
                                ))}
                              </div>
                            </Table.Cell>
                            <Table.Cell className="text-center font-bold text-green-500">
                              {formatCurrencyBRL.format(command.totalPrice)}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                              <Button
                                className="primary mx-auto"
                                onClick={() => handleChangeInvoice(command)}
                              >
                                <FiEye />
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>

              {totalPages > 1 && (
                <div className="flex overflow-x-auto justify-center mt-3">
                  <Pagination
                    layout="pagination"
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    previousLabel=""
                    nextLabel=""
                    showIcons
                  />
                </div>
              )}
            </>
          ) : (
            <EmptyTable icon="search_off">
              Ops! Nenhuma comanda encontrada para este período.
            </EmptyTable>
          )}
        </>
      ) : (
        <Loading />
      )}

      <Drawer
        title={"Visualizar comanda"}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <InvoiceViewForm
          invoice={selectedInvoice}
          onClose={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          professionals={professionals}
        />
      </Drawer>

      <Modal
        show={isOpenModalPaymentProfessional}
        size="md"
        onClose={() => setOpenModalPaymentProfessional(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div>
            <div className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                Período:{" "}
                <Badge className="w-fit" color="gray">
                  {moment(startDatePeriod).format("DD/MM/YY")} à{" "}
                  {moment(endDatePeriod).format("DD/MM/YY")}
                </Badge>
              </div>
              <div>
                Profissional:{" "}
                <span className="font-semibold">
                  {getSelectedProfessional()?.name}
                </span>
              </div>
              <div>
                Porcentagem:{" "}
                <span className="font-semibold">
                  {getSelectedProfessional()?.percentageCommission}%
                </span>
              </div>
              {!isLoadingCommandsPaymentProfessional ? (
                <>
                  {" "}
                  <div>
                    Total de serviços:{" "}
                    <span className="font-semibold">
                      {formatCurrencyBRL.format(calculateTotalServices())}
                    </span>
                  </div>
                  <div>
                    Total de produtos:{" "}
                    <span className="font-semibold">
                      {formatCurrencyBRL.format(calculateTotalProducts())}
                    </span>
                  </div>
                </>
              ) : (
                <div className="font-semibold">Carregando informações...</div>
              )}

              <small>
                Observação: O valor total já inclui a dedução da porcentagem do
                profissional.
              </small>
            </div>
            <div className="flex justify-center gap-2">
              <Button
                className="primary"
                onClick={() => setOpenModalPaymentProfessional(false)}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoicesTable;
