import { AiOutlineLoading } from "react-icons/ai";
import { Accordion, Alert, Button } from "flowbite-react";
import { useForm } from "react-hook-form";
import { PROFESSIONALS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { resetValuesForm } from "../../utils/utils";
import { HiInformationCircle } from "react-icons/hi";
import { useQuery } from "react-query";

const ProfessionalServicesForm = ({
  categories,
  professional,
  fetchProfessionals,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [isQueryEnabledServices, setIsQueryEnabledServices] = useState(false);
  const {
    isLoadingProfessionalServices,
    errorProfessionalServices,
    data: professionalServices,
    refetch,
  } = useQuery(
    ["professionalServices"],
    async () => {
      const { data: items } = await axios.get(
        `${PROFESSIONALS.GET_PROFESSIONAL}/services/${professional?.id}`
      );
      return items.data;
    },
    { enabled: isQueryEnabledServices }
  );

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setError(null);
    reset();
    categories?.forEach((category, catIdx) => {
      category?.Services?.forEach((service, index) => {
        setValue(
          `categories[${catIdx}].services[${index}].serviceId`,
          service.id
        );
      });
    });
    setIsQueryEnabledServices(true);
    refetch();
  }, [professional]);

  useEffect(() => {
    categories?.forEach((category, catIdx) => {
      category?.Services?.forEach((service, index) => {
        if (professionalServices && professionalServices.some((ps) => ps.ServiceId === service.id)) {
          setValue(`categories[${catIdx}].services[${index}].isCheck`, true);
        }
      });
    });
  }, [professionalServices]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      let newArrayServices = [];

      data.categories.forEach((cat) => {
        cat.services.forEach((service) => {
          newArrayServices = [...newArrayServices, service];
        });
      });

      data.services = newArrayServices.filter((service) => service.isCheck);

      await axios.put(
        `${PROFESSIONALS.GET_PROFESSIONAL}/services/${professional.id}`,
        data
      );

      onClose(false);

      //fetchProfessionals();
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="font-semibold mb-5">Profissional: {professional?.name}</div>
      {categories?.length > 0 ? (
        <>
          <Accordion style={{ maxWidth: "600px" }} collapseAll>
            {categories?.map((category, idxCat) => (
              <Accordion.Panel key={idxCat}>
                <Accordion.Title>{category.name} ({category?.Services.length})</Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col gap-4">
                    {category?.Services?.length > 0 ? (
                      <>
                        {category.Services.map((service, index) => (
                          <div key={index}>
                            <label className="flex items-center cursor-pointer justify-between">
                              <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                {service.name}
                              </span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="sr-only peer"
                                  name={`categories[${idxCat}].services[${index}]`}
                                  {...register(
                                    `categories[${idxCat}].services[${index}].isCheck`
                                  )}
                                  checked={watch(
                                    `categories[${idxCat}].services[${index}].isCheck`
                                  )}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700"></div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="text-sm md:text-md text-center">
                        Não há nenhum serviço cadastrado nesta categoria
                      </div>
                    )}
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
          <Button
            type="submit"
            className="w-full mb-48 md:mb-4 primary mt-6"
            isProcessing={isSubmitting}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            disabled={isSubmitting}
          >
            Atualizar serviços
          </Button>

          {error && (
            <Alert color="failure" icon={HiInformationCircle}>
              Erro ao atualizar serviços do profissional
            </Alert>
          )}
        </>
      ) : (
        <div className="text-sm md:text-md text-center">
          Não há nenhuma categoria cadastrada. <br />
          Comece cadastrando as categorias e os serviços primeiro.
        </div>
      )}
    </form>
  );
};

export default ProfessionalServicesForm;
