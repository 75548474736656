import moment from "moment";
import { Card } from "flowbite-react";
import { useEffect, useState } from "react";

const CalendarCard = ({ date, onClick, selectedDate, view }) => {
 //TODO MELHORAR ESSA FUNCAO AQUI
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedSelectedDate, setFormattedSelectedDate] = useState("");
  const [formattedDayOfWeek, setFormattedDayOfWeek] = useState("");
  const [formattedDay, setFormattedDay] = useState("");
  const [formattedMonth, setFormattedMonth] = useState("");
  const [formattedYear, setFormattedYear] = useState("");
  const momentDate = moment(date);
  const dateToCompare = momentDate.format("DD-MM-YYYY");
  const currentDate = moment().format("DD-MM-YYYY");
  const momentSelectedDate = moment(selectedDate);

  //TODO FAZER OS FERIADOS
  const holidays = [
    {
        date: '26-04-2024',
        name: 'Dia das mães'
    }
  ];

  useEffect(() => {
    setFormattedMonth(momentDate.format("MMM"));
    setFormattedYear(momentDate.format("YYYY"));
    setFormattedDate(momentDate.format("YYYY-MM"));
    setFormattedSelectedDate(momentSelectedDate.format("YYYY-MM"));

    if (view !== "month") {
      setFormattedDayOfWeek(momentDate.format("ddd"));
      setFormattedDay(momentDate.format("D"));
      setFormattedMonth(momentDate.format("MMMM"));
      setFormattedDate(momentDate.format("YYYY-MM-DD"));
      setFormattedSelectedDate(momentSelectedDate.format("YYYY-MM-DD"));
    }
  }, [date, view]);

  const checkHoliday = () => {
    return holidays.some(holiday => holiday.date === dateToCompare);
  }

  return (
    <Card
      className={`w-full shadow-md border-0 ${checkHoliday() && 'bg-green-200'} ${
        dateToCompare === currentDate && "bg-gray-300"
      } ${formattedDate === formattedSelectedDate && "bg-primary"}`}
      onClick={onClick}
      style={{height: '120px'}}
    >
      <div
        className={`card-content text-center ${
          formattedDate === formattedSelectedDate && "text-white"
        }`}
      >
        {view !== "month" && <small>{formattedDayOfWeek}</small>}
        <h2 className="font-bold text-2xl">
          {view !== "month" ? formattedDay : formattedMonth}
        </h2>
        <p>{view !== "month" ? formattedMonth : formattedYear}</p>
      </div>
    </Card>
  );
};

export default CalendarCard;
