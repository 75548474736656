import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { CUSTOMERS } from "../../config/apiConfig";
import { Button, Pagination, Card, Badge } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import CustomerForm from "../forms/CustomerForm";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import { checkIfNewCustomer, getFirstLettersName } from "../../utils/utils";
import EmptyTable from "./EmptyTable";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import FilterToolbar from "../inputs/FilterToolbar";
import { FaSortAlphaDown, FaWhatsapp } from "react-icons/fa";
import { GoPersonFill } from "react-icons/go";
import CustomerInfosForm from "../forms/CustomerInfosForm";
import { BiSolidUserDetail } from "react-icons/bi";

const CustomersTable = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [sortName, setSortName] = useState("ASC");
  const [isVip, setIsVip] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const queryClient = useQueryClient();
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerInfosOpen, setIsDrawerInfosOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const [shouldFetchCustomerInfos, setShouldFetchCustomerInfos] =
    useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: customers,
  } = useQuery(
    ["customers", currentPage, nameParam, sortName, isVip],
    async () => {
      const { data: items } = await axios.get(
        `${CUSTOMERS.GET_CUSTOMERS}?page=${currentPage}&name=${searchName}&sortName=${sortName}&isVip=${isVip}`
      );
      setTotalPages(items.data.totalPages || 1);
      setTotalCount(items.data.totalCount || 0);
      return items.data.data;
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [nameParam]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const handleAddCustomer = () => {
    setSelectedCustomer(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleViewInfosCustomer = (customer) => {
    setShouldFetchCustomerInfos(true);
    setSelectedCustomer(customer);
    setIsDrawerInfosOpen((prevState) => !prevState);
  };

  const handleEditCustomer = (customer) => {
    setTypeDrawer("EDIT");
    setSelectedCustomer(customer);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteCustomer = (customer) => {
    setSelectedCustomer(customer);
    setOpenModalDelete(true);
  };

  const deleteCustomer = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${CUSTOMERS.GET_CUSTOMER}/${selectedCustomer.id}`);
      queryClient.invalidateQueries(["customers", currentPage]);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  return (
    <>
      <div className="flex justify-between mb-3 mt-2 items-center">
        <FilterToolbar
          isVisible={isVisibleFilter}
          setIsVisible={setIsVisibleFilter}
        >
          <div className="flex gap-2">
            <div className="flex gap-2 flex-col md:flex-row w-full">
              <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
                <FaSortAlphaDown className="color-secondary text-xl mt-1" />
                <select
                  id="underline_select"
                  className="mt-1 block py-1 px-0 w-full text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  value={sortName}
                  onChange={(e) => setSortName(e.target.value)}
                  syle={{ paddingRight: "30px" }}
                >
                  <option value="ASC" selected>
                    A - Z
                  </option>
                  <option value="DESC">Z - A</option>
                </select>
              </div>
              <div className="flex items-center gap-2 w-full bg-gray-100 rounded-md pl-2 pr-0 py-1">
                <GoPersonFill className="color-secondary text-xl mt-1" />
                <select
                  id="underline_select"
                  className="mt-1 block py-1 px-0 w-full md:w-40 text-sm text-gray-600 bg-transparent border-0 border-gray-300 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  value={isVip}
                  onChange={({ target }) => setIsVip(target.value)}
                >
                  <option selected value={-1}>
                    Tipo do cliente
                  </option>
                  <option value={0}>Apenas Comum</option>
                  <option value={1}>Apenas Vip</option>
                </select>
              </div>
            </div>
          </div>
        </FilterToolbar>
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddCustomer}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Adicionar cliente</span>
        </Button>
      </div>
      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>
      {!isLoading ? (
        <>
          {totalCount ? (
            <div className="text-sm mt-5">
              Total de <strong>{totalCount}</strong> clientes
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          role="status"
          className="max-w-sm animate-pulse mt-5"
          style={{ height: "20px" }}
        >
          <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
        </div>
      )}

      {!isLoading ? (
        <>
          {customers.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
              {customers.map((customer, index) => (
                <Card key={index} className="relative">
                  <div className="flex gap-3 items-center">
                    <div
                      className="flex items-center justify-center bg-primary"
                      style={{
                        height: "40px",
                        width: "40px",
                        fontSize: "18px",
                        color: "white",
                        borderRadius: "50%",
                        minWidth: "40px",
                      }}
                    >
                      {getFirstLettersName(customer.name)}
                    </div>
                    <h2>{customer.name}</h2>
                    {checkIfNewCustomer(customer.createdAt) && (
                      <Badge color="pink">Novo</Badge>
                    )}
                  </div>
                  <div className="flex gap-1 text-gray-500">
                    <Icon fontSize="small">phone</Icon>
                    <span className="text-sm">{customer.phoneNumber}</span>
                    <a
                      className="ml-1"
                      target="_blank"
                      href={`https://wa.me/55${customer?.phoneNumber.replace(
                        /[\(\)\s,-]/g,
                        ""
                      )}`}
                    >
                      <FaWhatsapp className="text-green-500" />
                    </a>
                  </div>
                  <div className="flex gap-1 text-gray-500 items-center">
                    <Icon fontSize="small">description</Icon>
                    <span className="text-xs truncate mr-32 mt-1">
                      Obs: {customer.observation || "Não informado"}
                    </span>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8">
                    <BiSolidUserDetail
                      className="text-gray-500 cursor-pointer"
                      fontSize={25}
                      onClick={() => handleViewInfosCustomer(customer)}
                    />
                    <Icon
                      fontSize="small"
                      className="text-gray-500 cursor-pointer"
                      onClick={() => handleEditCustomer(customer)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="text-red-700 cursor-pointer"
                      onClick={() => handleDeleteCustomer(customer)}
                    >
                      delete
                    </Icon>
                  </div>
                  <div className="ribbon-container">
                    {customer.isVip && (
                      <div className="ribbon down z-1">
                        <div className="content">VIP</div>
                      </div>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="person_add">
              Ops! Nenhum cliente encontrado. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${selectedCustomer?.name}`}
        onClose={() => {
          setIsDrawerInfosOpen(false);
          setShouldFetchCustomerInfos(false);
        }}
        isOpen={isDrawerInfosOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <CustomerInfosForm
          customer={selectedCustomer}
          onClose={setIsDrawerInfosOpen}
          isDrawerOpen={isDrawerInfosOpen}
          shouldFetchCustomerInfos={shouldFetchCustomerInfos}
        />
      </Drawer>

      <Drawer
        title={`${
          typeDrawer === "CREATE" ? "Cadastrar novo cliente" : "Editar cliente"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className="w-full md:w-3/6 pb-36"
      >
        <CustomerForm
          customer={selectedCustomer}
          handleInputChange={handleInputChange}
          fetchCustomers={() =>
            queryClient.invalidateQueries(["customers", currentPage])
          }
          onClose={setIsDrawerOpen}
          type={typeDrawer}
          isDrawerOpen={isDrawerOpen}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deleteCustomer}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir esse cliente?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default CustomersTable;
