import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import { AVATAR, CATEGORIES, SERVICES } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { Alert, Button } from "flowbite-react";
import { useQuery } from "react-query";
import { AiOutlineLoading } from "react-icons/ai";
import SelectInput from "../inputs/SelectInput";
import { calculateTimeService } from "../../utils/utils";
import { HiInformationCircle } from "react-icons/hi";
import ImageInput from "../inputs/ImageInput";
import TextAreaInput from "../inputs/TextAreaInput";

const ServiceForm = ({
  service,
  fetchServices,
  isDrawerOpen,
  onClose,
  type = "CREATE",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [key, setKey] = useState(0);
  const [avatarImg, setAvatarImg] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();
  const {
    isLoading,
    error,
    data: categories,
  } = useQuery(["categories"], async () => {
    const { data: items } = await axios.get(`${CATEGORIES.GET_CATEGORIES}`);
    return items.data.data;
  });

  useEffect(() => {
    reset();
    setErrorSubmit(null);

    if (type === "EDIT" && service) {
      Object.keys(service).forEach((key) => {
        setValue(key, service[key]);
      });
      setKey((prev) => prev + 1);
      setPreviewUrl(service?.imageUrl);
      setAvatarImg(service?.avatar);
    } else if (type === "CREATE") {
      reset();
      setPreviewUrl("");
      setAvatarImg("");
    }
  }, [service, type, isDrawerOpen]);

  const onSubmit = async (data) => {
    try {
      data.price = `${data.price}`.replace(",", ".");
      setIsSubmitting(true);

      if (previewUrl.includes("data:image/")) {
        const formData = new FormData();
        formData.append("image", avatarImg);
        const { data: avatar } = await axios.post(
          `${AVATAR.UPLOAD}?avatar=${service?.avatar || ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        data.avatar = avatar.imageName;
      }

      if (type === "CREATE") {
        await axios.post(SERVICES.GET_SERVICE, data);
      } else {
        await axios.put(`${SERVICES.GET_SERVICE}/${service.id}`, data);
      }

      onClose(false);

      fetchServices();
      if (type === "CREATE") reset();
    } catch (error) {
      console.error(error);
      setErrorSubmit(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <ImageInput
            image={avatarImg}
            setImage={setAvatarImg}
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
          />
        </div>
        <div className="mb-6">
          <Input
            type="text"
            label="Nome *"
            name="name"
            required={true}
            register={register}
            error={errors?.name && "Este campo é obrigatório"}
          />
        </div>
        <div className="mb-6 flex gap-2">
          <div className="w-48">
            <SelectInput
              label="Preço *"
              name="typePrice"
              required={true}
              register={register}
            >
              <option value="F">Preço fixo</option>
              <option value="V">A partir de</option>
            </SelectInput>
          </div>
          <Input
            type="number"
            label="Preço do serviço R$ *"
            name="price"
            required={true}
            register={register}
            error={errors?.price && "Este campo é obrigatório"}
            step="0.01"
            className="w-full"
          />
        </div>
        <div className="mb-6">
          <SelectInput
            label="Duração do serviço *"
            name="durationMinutes"
            required={true}
            register={register}
            error={errors?.durationMinutes && "Este campo é obrigatório"}
          >
            {calculateTimeService().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectInput>
        </div>
        <div className="mb-6">
          <TextAreaInput
            type="text"
            label="Descrição do serviço"
            name="description"
            required={false}
            register={register}
            placeholder="Descreva brevemente o serviço oferecido"
          />
        </div>
        <div className="mb-6">
          <select
            id="categoryId"
            name="categoryId"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            {...register("categoryId", { required: true })}
          >
            <option value="" selected>
              Selecione a categoria
            </option>
            {!isLoading &&
              categories.map((category, index) => (
                <option key={index} value={`${category.id}`}>
                  {category.name}
                </option>
              ))}
          </select>
          {errors?.categoryId && (
            <small className="text-red-400">
              Você precisa selecionar uma categoria!
            </small>
          )}
        </div>

        <Button
          type="submit"
          className="w-full primary mb-56 md:mb-10"
          isProcessing={isSubmitting}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
          disabled={isSubmitting}
        >
          {type === "CREATE" ? "Criar" : "Atualizar"} serviço
        </Button>
      </form>
      {errorSubmit && (
        <Alert color="failure" icon={HiInformationCircle}>
          Erro ao {errorSubmit.config.method === "put" ? "atualizar" : "criar"}{" "}
          serviço
        </Alert>
      )}
    </>
  );
};

export default ServiceForm;
