import { Icon } from "@mui/material";
import { Button, Modal } from "flowbite-react";
import { convertMinutesToHours, formatCurrencyBRL } from "../../utils/utils";

const ModalServiceDetails = ({ isOpen, setOpenModal, service }) => {
  return (
    <>
      <Modal show={isOpen} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div>
            <div className="text-center flex flex-col gap-2 items-center">
              {!service?.imageUrl ? (
                <div
                  className="flex items-center justify-center"
                  style={{
                    height: "60px",
                    width: "60px",
                    fontSize: "18px",
                    color: "black",
                    borderRadius: "50%",
                    minWidth: "60px",
                    background: "var(--primary)",
                  }}
                >
                  <Icon className="text-white" fontSize="small">
                    design_services
                  </Icon>
                </div>
              ) : (
                <div>
                  <img
                    src={service?.imageUrl}
                    className="rounded-full"
                    style={{
                      objectFit: "cover",
                      width: "60px",
                      minWidth: "60px",
                      height: "60px",
                    }}
                  />
                </div>
              )}
              <h3 className="text-base md:text-lg font-semibold text-gray-700 dark:text-gray-400">
                {service?.name}
              </h3>
              <div className="flex gap-3 text-sm">
                <div>
                  {service?.typePrice === "F" ? "Preço: " : "A partir de "}
                  {formatCurrencyBRL.format(service?.price)}
                </div>
                <div>Duração: {convertMinutesToHours(service?.durationMinutes)}h</div>
              </div>
              <p className="mb-5 text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">
                {service?.description}
              </p>
              <div className="flex justify-center gap-4">
                <Button color="gray" onClick={() => setOpenModal(false)}>
                  Fechar
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalServiceDetails;
