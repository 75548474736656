import { Badge, Card } from "flowbite-react";
import moment from "moment";
import { formatCurrencyBRL } from "../../utils/utils";

const InvoiceViewForm = ({ invoice, isDrawerOpen, onClose, professionals }) => {
  const uniqueProfessionalIdsServices = [
    ...new Set(
      invoice?.Services?.map((item) => item.CommandService.ProfessionalId)
    ),
  ];

  const uniqueProfessionalIdsProducts = [
    ...new Set(
      invoice?.Products?.map((item) => item.CommandProduct.ProfessionalId)
    ),
  ];
  const calculateTotalServicesProfessional = (professionalId) => {
    const totalPrice = invoice.Services.reduce((acc, service) => {
      if (service.CommandService.ProfessionalId === professionalId && !service.CommandService.isPackage) {
        return (
          acc +
          +service.CommandService.priceUnit * +service.CommandService.quantity
        );
      }
      return acc + 0;
    }, 0);

    return (
      totalPrice *
      (professionals.find((professional) => professional.id === professionalId)
        ?.percentageCommission /
        100)
    );
  };

  const calculateTotalProductsProfessional = (professionalId) => {
    const totalPrice = invoice.Products.reduce((acc, product) => {
      if (product.CommandProduct.ProfessionalId === professionalId) {
        return (
          acc +
          +product.CommandProduct.priceUnit * +product.CommandProduct.quantity
        );
      }
    }, 0);

    return (
      totalPrice *
      (professionals.find((professional) => professional.id === professionalId)
        ?.percentageCommission /
        100)
    )
      ?.toFixed(2)
      .replace(".", ",");
  };

  return (
    <div>
      <div className="mb-3">
        <span className="font-semibold">Cliente:</span>
        <span> {invoice?.Customer?.name}</span>
      </div>
      <div>
        <span className="font-semibold">Data da comanda:</span>
        <span> {moment(invoice?.date).format("DD/MM/YYYY")}</span>
      </div>
      {invoice?.Services && invoice.Services.length > 0 && (
        <Card className="mt-5 card-invoice">
          <div className="font-semibold">Serviços realizados:</div>
          <ul>
            {invoice?.Services.map((service, index) => (
              <li
                key={index}
                className="flex justify-between mb-2 items-center"
              >
                <span className="text-xs md:text-base">{service?.name}</span>
                <div className="flex gap-2 md:gap-4">
                  {service.CommandService.isPackage && (
                    <Badge color="gray">Pacote</Badge>
                  )}
                  <Badge color="gray">
                    Qtd. {service.CommandService.quantity}
                  </Badge>
                  <Badge color="green">
                    {!service.CommandService.isPackage ? formatCurrencyBRL.format(
                      service.CommandService.priceUnit *
                        service.CommandService.quantity
                    ): "R$ 0"}
                  </Badge>
                  <Badge>
                    {
                      professionals?.find(
                        (professional) =>
                          professional.id ===
                          service.CommandService.ProfessionalId
                      )?.name
                    }
                  </Badge>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex flex-col gap-2">
            <span className="font-semibold ">Comissões dos profissionais:</span>
            {uniqueProfessionalIdsServices.length > 0 &&
              uniqueProfessionalIdsServices.map((professionalId, index) => (
                <div key={index}>
                  <div>
                    Comissão de{" "}
                    {
                      professionals.find(
                        (professional) => professional.id === professionalId
                      )?.name
                    }
                    :{" "}
                    <b>
                      {formatCurrencyBRL.format(
                        calculateTotalServicesProfessional(professionalId)
                      )}
                    </b>
                  </div>
                </div>
              ))}
          </div>
        </Card>
      )}

      {invoice?.Products && invoice.Products.length > 0 && (
        <Card className="mt-5 card-invoice">
          <div className="font-semibold">Produtos comprados:</div>
          <ul>
            {invoice?.Products.map((product, index) => (
              <li
                key={index}
                className="flex justify-between mb-2 items-center"
              >
                <span className="text-xs md:text-base">{product?.name}</span>
                <div className="flex gap-2 md:gap-4">
                  <Badge color="gray">
                    Qtd. {product.CommandProduct.quantity}
                  </Badge>
                  <Badge color="green">
                    {formatCurrencyBRL.format(
                      product.CommandProduct.priceUnit *
                        product.CommandProduct.quantity
                    )}
                  </Badge>
                  <Badge>
                    {
                      professionals?.find(
                        (professional) =>
                          professional.id ===
                          product.CommandProduct.ProfessionalId
                      )?.name
                    }
                  </Badge>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex flex-col gap-2">
            <span className="font-semibold">Comissão dos profissionais:</span>
            {uniqueProfessionalIdsProducts.length > 0 &&
              uniqueProfessionalIdsProducts.map((professionalId, index) => (
                <div key={index}>
                  <div>
                    Comissão de{" "}
                    {
                      professionals.find(
                        (professional) => professional.id === professionalId
                      )?.name
                    }
                    :{" "}
                    <b>
                      {formatCurrencyBRL.format(
                        calculateTotalProductsProfessional(professionalId)
                      )}
                    </b>
                  </div>
                </div>
              ))}
          </div>
        </Card>
      )}

      {invoice?.Payments && invoice.Payments.length > 0 && (
        <Card className="mt-4 card-invoice">
          <div className="font-semibold">Métodos de pagamento:</div>
          {invoice?.Payments.map((payment, index) => (
            <div className="flex justify-between mb-2" key={index}>
              <div className="text-xs md:text-base">{payment?.name}</div>
              <div className="flex gap-4">
                <div className="text-xs md:text-base">
                  {formatCurrencyBRL.format(payment.CommandPayment.price)}
                </div>
                <div className="text-xs md:text-base">
                  Tx. {payment.CommandPayment.taxPercentage}%
                </div>
              </div>
            </div>
          ))}
        </Card>
      )}

      <div className="flex gap-2 mt-4">
        <Card className="w-full card-invoice">
          <div className="font-semibold">Descontos totais:</div>
          <Badge color="pink" className="w-fit" size="large">
            {formatCurrencyBRL.format(invoice?.discount || 0)}
          </Badge>
        </Card>
        <Card className="w-full card-invoice">
          <div className="font-semibold">Preço total:</div>
          <Badge color="green" className="w-fit" size="large">
            {formatCurrencyBRL.format(invoice?.totalPrice)}
          </Badge>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceViewForm;
