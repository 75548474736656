import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Drawer from "../Drawer";
import { CATEGORIES } from "../../config/apiConfig";
import { Button, Card, Pagination } from "flowbite-react";
import ModalDelete from "../modals/ModalDelete";
import axios from "../../config/axiosInstance";
import CategoryForm from "../forms/CategoryForm";
import CardLoading from "../CardLoading";
import { useQuery, useQueryClient } from "react-query";
import EmptyTable from "./EmptyTable";
import { IoMdAdd } from "react-icons/io";

const CategoriesTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const queryClient = useQueryClient();
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [typeDrawer, setTypeDrawer] = useState("CREATE");
  const onPageChange = (page) => setCurrentPage(page);
  const {
    isLoading,
    error,
    data: categories,
  } = useQuery(["categories", currentPage], async () => {
    const { data: items } = await axios.get(
      `${CATEGORIES.GET_CATEGORIES}?page=${currentPage}`
    );
    setTotalPages(items.data.totalPages || 1);
    return items.data.data;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCategory((prevCategory) => ({
      ...prevCategory,
      [name]: value,
    }));
  };

  const handleAddCategory = () => {
    setSelectedCategory(null);
    setTypeDrawer("CREATE");
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleEditCategory = (category) => {
    setTypeDrawer("EDIT");
    setSelectedCategory(category);
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDeleteCategory = (category) => {
    setSelectedCategory(category);
    setOpenModalDelete(true);
  };

  const deleteCategory = async () => {
    try {
      setIsLoadingDelete(true);
      await axios.delete(`${CATEGORIES.GET_CATEGORY}/${selectedCategory.id}`);
      queryClient.invalidateQueries(["categories", currentPage]);

      setOpenModalDelete(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <div className="flex justify-end mb-1 mt-2">
        <Button
          className="primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-lg md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center"
          variant="contained"
          disabled={isLoading ? true : false}
          onClick={handleAddCategory}
        >
          <IoMdAdd className="block md:hidden text-2xl" />
          <span className="hidden md:block">Adicionar categoria</span>
        </Button>
      </div>
      {!isLoading ? (
        <>
          {categories.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4 mt-4">
              {categories.map((category, index) => (
                <Card key={index} className="relative">
                  <div className="flex gap-3 items-center">
                    <h2>{category.name}</h2>
                  </div>
                  <div className="flex gap-1 text-gray-500 mr-16 truncate">
                    <Icon fontSize="small">design_services</Icon>
                    <span className="text-sm">
                      {category.Services.length} serviços nesta categoria
                    </span>
                  </div>
                  <div className="flex gap-1 text-gray-500 mr-16 truncate">
                    <Icon fontSize="small">shopping_bag</Icon>
                    <span className="text-sm">
                      {category.Products.length} produtos nesta categoria
                    </span>
                  </div>
                  <div className="flex absolute bottom-5 right-3 gap-8">
                    <Icon
                      fontSize="small"
                      className="cursor-pointer text-gray-500"
                      onClick={() => handleEditCategory(category)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      className="color-primary cursor-pointer"
                      onClick={() => handleDeleteCategory(category)}
                    >
                      delete
                    </Icon>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyTable icon="add_circle_outline">
              Ops! Nenhuma categoria encontrada. <br />
              Clique no botão e comece a cadastrar agora mesmo.
            </EmptyTable>
          )}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 mt-4">
          {Array.from({ length: 12 }).map((_, index) => (
            <CardLoading key={index} />
          ))}
        </div>
      )}
      
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}

      <Drawer
        title={`${
          typeDrawer === "CREATE"
            ? "Cadastrar nova categoria"
            : "Editar categoria"
        }`}
        onClose={setIsDrawerOpen}
        isOpen={isDrawerOpen}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <CategoryForm
          category={selectedCategory}
          handleInputChange={handleInputChange}
          fetchCategories={() =>
            queryClient.invalidateQueries(["categories", currentPage])
          }
          onClose={setIsDrawerOpen}
          type={typeDrawer}
        />
      </Drawer>
      <ModalDelete
        handleDelete={deleteCategory}
        isOpen={isOpenModalDelete}
        setOpenModal={setOpenModalDelete}
        title="Tem certeza que deseja excluir essa categoria?"
        isLoading={isLoadingDelete}
      />
    </>
  );
};

export default CategoriesTable;
