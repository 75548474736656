import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useApplication } from "../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalConfirmAdditionalPlan = ({ planId, plans }) => {
  const {
    additionalPlansCompany,
    isLoadingAddtionalPlansCompany,
    userPermission,
    user,
  } = useApplication();
  const [isOpen, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingAddtionalPlansCompany) {
      if (
        !additionalPlansCompany?.includes(planId) &&
        !plans.includes(user?.Company.planId)
      ) {
        setOpenModal(true);
      }
    }
  }, [additionalPlansCompany]);

  return (
    <>
      <Modal show={isOpen} size="md" onClose={() => navigate(-1)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {userPermission === "ADMIN" ? (
              <>
                <div className="font-semibold text-gray-600">
                  Quer levar seu negócio para o próximo nível? ⭐
                </div>
                <div className="mb-5">
                  Este recurso é a chave para impulsionar ainda mais seus
                  resultados.
                </div>
                <div className="flex justify-center gap-2">
                  <Button
                    className="bg-green-500"
                    onClick={() => navigate("/additional-plans")}
                  >
                    Contratar agora
                  </Button>
                  <Button className="bg-gray-500" onClick={() => navigate(-1)}>
                    Agora não
                  </Button>
                </div>
              </>
            ) : (
              <>
                <HiOutlineExclamationCircle className="mx-auto mb-2 h-14 w-14 text-red-500 dark:text-gray-200" />
                <h3 className="mb-2 text-lg font-normal text-red-500 dark:text-red-500 font-semibold">
                  Ops, acesso não disponível!
                </h3>
                <div className="font-semibold text-gray-600">
                  Contate o administrador dessa empresa para contratar essa
                  funcionalidade.
                </div>
                <div className="flex justify-center gap-2">
                  <Button className="bg-gray-500" onClick={() => navigate(-1)}>
                    Fechar
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirmAdditionalPlan;
