import {
  Button,
  Checkbox,
  Label,
  Radio,
  Select,
  Tabs,
  TextInput,
  Timeline,
} from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { FaArrowDown, FaTrash } from "react-icons/fa";
import axios from "../../config/axiosInstance";
import { RECORDS } from "../../config/apiConfig";
import { AiOutlineLoading } from "react-icons/ai";

const RecordForm = ({ onClose, fetchRecords, record, type, isOpen }) => {
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [titleForm, setTitleForm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasOnlineSignature, setHasOnlineSignature] = useState(false);
  const [fieldsForm, setFieldsForm] = useState([
    {
      fieldName: "",
      fieldType: "text",
      options: [],
    },
  ]);

  useEffect(() => {
    setTitleForm("");
    setFieldsForm([
      {
        fieldName: "",
        fieldType: "text",
        options: [],
      },
    ]);
    if (type === "EDIT" && record) {
      setFieldsForm(record.form);
      setTitleForm(record.name);
      setHasOnlineSignature(record?.hasOnlineSignature);
    }
  }, [record, type]);

  const handleFieldNameChange = (index, event) => {
    const newFieldsForm = [...fieldsForm];
    newFieldsForm[index].fieldName = event.target.value;
    setFieldsForm(newFieldsForm);
  };

  const handleFieldTypeChange = (index, event) => {
    const newFieldsForm = [...fieldsForm];
    newFieldsForm[index].fieldType = event.target.value;
    newFieldsForm[index].options = [];
    setFieldsForm(newFieldsForm);
  };

  const handleOptionChange = (fieldIndex, optionIndex, event) => {
    const newFieldsForm = [...fieldsForm];
    newFieldsForm[fieldIndex].options[optionIndex] = event.target.value;
    setFieldsForm(newFieldsForm);
  };

  const addField = () => {
    setFieldsForm([
      ...fieldsForm,
      {
        fieldName: "",
        fieldType: "text",
        options: [],
      },
    ]);
  };

  const removeField = () => {
    setFieldsForm((prev) => prev.slice(0, -1));
  };

  const addOption = (index) => {
    const newFieldsForm = [...fieldsForm];
    newFieldsForm[index].options.push("");
    setFieldsForm(newFieldsForm);
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const newFieldsForm = [...fieldsForm];
    newFieldsForm[fieldIndex].options.splice(optionIndex, 1);
    setFieldsForm(newFieldsForm);
  };

  const handleSubmit = async (e) => {
    try {
      setIsSubmitting(true);
      e.preventDefault();
      const data = {};

      data.name = titleForm;
      data.form = fieldsForm;
      data.hasOnlineSignature = hasOnlineSignature;

      if (type === "CREATE") {
        await axios.post(RECORDS.GET_RECORD, data);
      } else {
        await axios.put(`${RECORDS.GET_RECORD}/${record.id}`, data);
      }

      onClose(false);
      fetchRecords();
      setTitleForm("");
      setFieldsForm([
        {
          fieldName: "",
          fieldType: "text",
          options: [],
        },
      ]);
      setHasOnlineSignature(false);
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col gap-3 pb-48 md:pb-12">
      <Tabs
        aria-label="Default tabs"
        style="default"
        ref={tabsRef}
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        <Tabs.Item active title="Editar" icon={MdModeEdit}>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label>Nome do Formulário: *</label>
              <input
                type="text"
                className="block rounded-md p-2 border-gray-300 border mt-1 mb-2 w-full"
                required
                onChange={(e) => setTitleForm(e.target.value)}
                value={titleForm}
              />
            </div>
            <Timeline>
              {fieldsForm?.map((field, index) => (
                <Timeline.Item key={index}>
                  <Timeline.Point />
                  <Timeline.Content>
                    <Timeline.Time>Campo {index + 1}</Timeline.Time>
                    <Timeline.Title></Timeline.Title>
                    <Timeline.Body>
                      <div>
                        <label>Nome do campo: *</label>
                        <input
                          value={field.fieldName}
                          onChange={(event) =>
                            handleFieldNameChange(index, event)
                          }
                          className="block rounded-md p-2 border-gray-300 border mt-1 mb-2 w-full"
                          placeholder="Insira o texto aqui"
                          required
                        />
                      </div>
                      <div>
                        <label>Tipo do campo:</label>
                        <Select
                          value={field.fieldType}
                          onChange={(event) =>
                            handleFieldTypeChange(index, event)
                          }
                          className="mt-1 mb-2"
                        >
                          <option value="text">Campo de Texto</option>
                          <option value="date">Campo de Data</option>
                          <option value="radio">Campo de Seleção única</option>
                          <option value="checkbox">
                            Campo de Seleção múltipla
                          </option>
                          <option value="title">Título</option>
                          <option value="text-only">Texto</option>
                        </Select>
                      </div>

                      {field.fieldType === "radio" && (
                        <div className="mt-4">
                          <div className="mb-3">Opções:</div>
                          {field.options.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="flex items-center justify-between gap-2 mb-2 shadow-md p-2 bg-gray-100 rounded-md"
                            >
                              <div className="flex gap-2 items-center">
                                <input
                                  value={option}
                                  placeholder="Edite o texto"
                                  onChange={(event) =>
                                    handleOptionChange(
                                      index,
                                      optionIndex,
                                      event
                                    )
                                  }
                                  className="bg-transparent"
                                  required
                                />
                              </div>
                              <Button
                                className="ml-2 bg-gray-400"
                                onClick={() => removeOption(index, optionIndex)}
                              >
                                <FaTrash />
                              </Button>
                            </div>
                          ))}
                          <Button
                            className="mt-2 secondary text-white"
                            onClick={() => addOption(index)}
                          >
                            Adicionar opção
                          </Button>
                        </div>
                      )}

                      {field.fieldType === "checkbox" && (
                        <div className="mt-4">
                          <div className="mb-3">Opções:</div>
                          {field.options.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="flex items-center justify-between gap-2 mb-2 shadow-md p-2 rounded-md bg-gray-100"
                            >
                              <div className="flex gap-2 items-center">
                                <input
                                  value={option}
                                  onChange={(event) =>
                                    handleOptionChange(
                                      index,
                                      optionIndex,
                                      event
                                    )
                                  }
                                  placeholder="Edite o texto"
                                  className="bg-transparent"
                                  required
                                />
                              </div>
                              <Button
                                className="ml-2 bg-gray-400"
                                onClick={() => removeOption(index, optionIndex)}
                              >
                                <FaTrash />
                              </Button>
                            </div>
                          ))}
                          <Button
                            className="mt-2 secondary text-white"
                            onClick={() => addOption(index)}
                          >
                            Adicionar opção
                          </Button>
                        </div>
                      )}
                    </Timeline.Body>
                  </Timeline.Content>
                </Timeline.Item>
              ))}
              <div className="flex gap-2">
                <Button className="ml-6 primary text-white" onClick={addField}>
                  Adicionar campo
                </Button>
                {fieldsForm?.length > 1 && (
                  <Button
                    className="bg-gray-500 text-white"
                    onClick={removeField}
                  >
                    Remover campo
                  </Button>
                )}
              </div>
            </Timeline>

            <label className="inline-flex items-center cursor-pointer mt-10">
              <input
                type="checkbox"
                className="sr-only peer"
                name={`hasOnlineSignature`}
                checked={hasOnlineSignature}
                onChange={() => {
                  setHasOnlineSignature((prev) => !prev);
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Possui assinatura online?
              </span>
            </label>

            <Button
              className="mt-6 w-full primary"
              type="submit"
              isProcessing={isSubmitting}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              disabled={isSubmitting}
            >
              {type === "CREATE" ? "Criar" : "Editar"} formulário
            </Button>
          </form>
        </Tabs.Item>
        <Tabs.Item active title="Visualizar" icon={FiEye}>
          <div>
            <div className="text-sm flex gap-2 items-center mb-6 text-gray-600 bg-gray-100 w-fit px-2 rounded-md py-1">
              <span>Veja como ficará o formulário para o seu cliente.</span>
              <FaArrowDown />
            </div>
            <div className="font-semibold mb-4 text-lg">{titleForm}</div>
            {fieldsForm?.map((field, index) => (
              <div key={index}>
                <div className="mb-5">
                  {(field.fieldType === "text" ||
                    field.fieldType === "date") && (
                    <>
                      <div className="mb-2 block">
                        <Label
                          htmlFor={`field-${index}`}
                          value={`${field.fieldName}`}
                        />
                      </div>
                      <TextInput
                        id={`field-${index}`}
                        type={field.fieldType}
                        required
                        disabled
                      />
                    </>
                  )}
                  {field.fieldType === "radio" && (
                    <>
                      <div className="mb-2 block">
                        <Label
                          htmlFor={`field-${index}`}
                          value={`${field.fieldName}`}
                        />
                      </div>
                      {field.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="flex items-center gap-2 mb-2"
                        >
                          <Radio name={`options-${index}`} disabled />
                          <Label value={`${option}`} />
                        </div>
                      ))}
                    </>
                  )}

                  {field.fieldType === "checkbox" && (
                    <>
                      <div className="mb-2 block">
                        <Label
                          htmlFor={`field-${index}`}
                          value={`${field.fieldName}`}
                        />
                      </div>
                      {field.options.map((option, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 mb-2"
                        >
                          <Checkbox name={`options-${index}`} disabled />
                          <Label value={`${option}`} />
                        </div>
                      ))}
                    </>
                  )}

                  {field.fieldType === "text-only" && (
                    <small>{field.fieldName}</small>
                  )}

                  {field.fieldType === "title" && (
                    <h3 className="font-semibold">{field.fieldName}</h3>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default RecordForm;
